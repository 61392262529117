import React, { useEffect, useContext, useState, Fragment } from "react";
import lottie from "lottie-web";
import { LanguageContext } from "../../contexts";
import { TutorialContainer } from "./tutorial.style";

function loadAnimation(animName) {
    const anim = lottie.loadAnimation({
        container: document.getElementById("tutorialContainer"), // the dom element that will contain the animation
        renderer: "svg",
        loop: false,
        autoplay: false,
        path: `assets/animations/tutorial/${animName}.json` // the path to the animation json
    });

    return anim;
}

const Tutorial = ({ callback }) => {
    const { lang } = useContext(LanguageContext);
    const [ animRef, setAnimRef ] = useState(false);

    useEffect(() => {
        setAnimRef(loadAnimation(`intro_${lang}`));
    }, []);

    useEffect(() => {
        if (animRef) {
            if (document.querySelector('.preloader').className.indexOf('loaded') === -1) {
                document.querySelector('.preloader').className += " loaded"
            }

            animRef.addEventListener("complete", function done() {
                animRef.removeEventListener("complete", done);
                animRef.destroy();
                setAnimRef(null);
                callback();
            });

            animRef.play()
        }
    }, [animRef, callback]);
    return (
        <Fragment>
            <TutorialContainer ready={!!animRef} id="tutorialContainer" />
        </Fragment>
    );
};

export default Tutorial;
