import enJSON from "../languages/en.json";
import frJSON from "../languages/fr.json";
import esJSON from "../languages/es.json";
import nlJSON from "../languages/nl.json";

export const cookieLangString = "visit-brussels-now-lang";

const languages = {
  es: esJSON,
  en: enJSON,
  fr: frJSON,
  nl: nlJSON
};

export default languages;

export function checkForLanguage(
  languageCookie,
  setCookie
) {
  const urlParts = window.location.pathname.split("/");
  const urlLAng = urlParts[1] ? urlParts[1] : null;
  const browserLanguage = navigator.language || navigator.userLanguage;
  const url = new URL(window.location.href);
  const urlLang = url.searchParams.get("lang");
  const language =
    urlLAng || languageCookie || urlLang || browserLanguage || null;

  // Check if valid language
  let langString = null;
  if (language) {
    Object.keys(languages).forEach(l => {
      if (language.toLowerCase().includes(l)) langString = l;
    });
  }

  if (langString && langString !== languageCookie) {
    setCookie(cookieLangString, langString, {
      path: "/",
      maxAge: 365 * 24 * 60 * 60 * 1000
    });
  }

  return langString
}
