import call from "./apicall";

/**
 * get event
 * @constructor
 */
export const getEvent = ({ lang, id }) => {
  if (process.env.NODE_ENV === "development") {
    console.info("API: GETEVENT", { lang, id });
  }
  const body = {
    id
  };
  return call("event", lang === "es" ? "sp" : lang, body);
};
