import styled from "styled-components";
import colors from "../../helpers/colors";
import cookiebg from "../../assets/images/cookiebg.png";
import { media } from "../../helpers/mediaQueries";
import brussel from "../../assets/images/brussel.png";

export const Container = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  /* background-image: url(${brussel});
  background-size: cover;
  background-position: center;
  background-color: ${colors.white}; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div`
  border-radius: 10px;
  /* height: 155px; */
  padding: 20px;
  box-sizing: border-box;
  width: calc(100% - 40px);
  background: #FFF url(${cookiebg});
  background-position: center;
  background-size: cover;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15), 0 10px 20px 0 rgba(0, 0, 0, 0.14);

  ${media.tablet`
    width: calc(50%);
  `}
  ${media.laptop`
    width: calc(40%);
  `}

  ${media.laptopL`
    width: calc(30%);
  `}
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Title = styled.span`
  font-family: Axiforma-semibold;
  font-size: 20px;
`;

export const Text = styled.span`
  font-family: Axiforma-light;
  font-size: 14px;
  opacity: 0.5;
`;

export const Deny = styled.span`
  color: ${colors.greyblue};
  font-family: Axiforma-semibold;
  font-size: 14px;
  margin-left: 20px;
  cursor: pointer;
  :hover {
    color: ${colors.blue};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  /* justify-content: space-around; */
`;
