import React, { useState, useContext } from "react";
import {
  Container,
  Languages,
  Language,
  LangDivider,
  ButtonContainer
} from "./languageSelect.style";
import { LanguageContext } from "../../contexts";
import { useCookies } from "react-cookie";
import languageSources, { cookieLangString } from "../../helpers/languages";
import Button from "../../components/Button";

const Tutorial = () => {
  const [cookies, setCookie] = useCookies([cookieLangString]); //eslint-disable-line
  const [selectedLang, setSelectLang] = useState(null);
  const { langSrc, setLang, setLangSrc } = useContext(LanguageContext);

  const updateLanguage = () => {
    setLang(selectedLang);
    setLangSrc(languageSources[selectedLang]);
    setCookie(cookieLangString, selectedLang, {
      path: "/",
      maxAge: 365 * 24 * 60 * 60 * 1000
    });
  };

  return (
    <Container>
      <Languages>
        <Language
          active={selectedLang === "fr"}
          onClick={() => setSelectLang("fr")}
        >
          Francais
        </Language>
        <LangDivider />
        <Language
          active={selectedLang === "nl"}
          onClick={() => setSelectLang("nl")}
        >
          Nederlands
        </Language>
        <LangDivider />
        <Language
          active={selectedLang === "en"}
          onClick={() => setSelectLang("en")}
        >
          English
        </Language>
        <LangDivider />
        <Language
          active={selectedLang === "es"}
          onClick={() => setSelectLang("es")}
        >
          Español
        </Language>
      </Languages>
      <ButtonContainer>
        <Button
          callback={selectedLang && updateLanguage}
          label={langSrc.next}
        />
      </ButtonContainer>
    </Container>
  );
};

export default Tutorial;
