import React from "react";
import PropTypes from "prop-types";
import { Container, Icon } from "./categoryItem.style";

const CategoryItem = ({ name, isActive, icon, onClick }) => (
  <Container onClick={onClick} noIcon={!icon} isActive={isActive}>
    {icon && <Icon src={icon} />}
    <span>{name}</span>
  </Container>
);

CategoryItem.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool
};

CategoryItem.defaultProps = {
  icon: null,
  isActive: false
};

export default CategoryItem;
