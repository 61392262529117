import { createContext } from "react";

const CategoryContext = createContext();
const LanguageContext = createContext();
const OverlayContext = createContext();
const PositionContext = createContext();
const DetailContext = createContext();

export {
  CategoryContext,
  LanguageContext,
  OverlayContext,
  PositionContext,
  DetailContext
};
