import React from "react";
import ContentLoader from "react-content-loader";

const PlacePlaceholder = () => (
  <ContentLoader
    height={322}
    width={400}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="20" y="270" rx="4" ry="4" width="50" height="19" />
    <rect x="20" y="300" rx="4" ry="4" width="100" height="22" />
    <rect x="0" y="0" rx="5" ry="5" width="400" height="250" />
  </ContentLoader>
);

export default PlacePlaceholder;
