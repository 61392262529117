import call from "./apicall";

/**
 * gets categories
 * @constructor
 */
export const getCategories = lang => {
  if (process.env.NODE_ENV === "development") {
    console.info("API: GETCATEGORIES", { lang });
  }
  return call("categories", lang === "es" ? "sp" : lang, {});
};
