import React, { useState, useEffect } from "react";
import { LoadingContainer } from "./loadingScreen.style";
import lottie from "lottie-web";
import { Breakpoints } from "../../helpers/mediaQueries";

function loadAnimation(animName) {
    const anim = lottie.loadAnimation({
        container: document.getElementById("loadingContainer"), // the dom element that will contain the animation
        renderer: "svg",
        loop: false,
        autoplay: false,
        path: `assets/animations/loading/${animName}.json` // the path to the animation json
    });

    return anim;
}

const LoadingScreen = ({ callback }) => {
    const [animRef, setAnimRef] = useState(null);
    const [animLoaded, setAnimLoaded] = useState(false);

    useEffect(() => {
        setAnimRef(loadAnimation("loading_square"));
    }, []);

    useEffect(() => {
        if (animRef && !animLoaded) {
            setAnimLoaded(true);

            if (document.querySelector('.preloader').className.indexOf('loaded') === -1) {
                document.querySelector('.preloader').className += " loaded"
            }

            animRef.addEventListener("complete", function done() {
                animRef.removeEventListener("complete", done);
                animRef.destroy();
                setAnimRef(null);
                callback();
            });

            animRef.play()
        }
    }, [animLoaded, animRef, callback]);
    return <LoadingContainer ready={!!animRef} id="loadingContainer" />;
};

export default LoadingScreen;
