import { css } from "styled-components";

export const Breakpoints = {
  mobile: 320,
  mobileL: 480,
  tablet: 768,
  laptop: 992,
  laptopL: 1240,
  desktop: 1440,
  desktopL: 1920,
  desktopXL: 2048
};

// Iterate through the sizes and create a media template
export const media = Object.keys(Breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${Breakpoints[label]}px) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});
