import styled from "styled-components";
import { ReactComponent as PopulationSVG } from "../../assets/icons/population.svg";
import { ReactComponent as AddressSVG } from "../../assets/icons/address.svg";
import { ReactComponent as CloseSVG } from "../../assets/icons/menu_close.svg";
import colors from "../../helpers/colors";
export const Container = styled.div`
  flex-direction: column;
  padding-bottom: 20px;
  width: 100%;
  background-color: white;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 50;
  position: ${props => (props.desktop ? "initial" : "fixed")};
`;
export const DateField = styled.div`
  background-color: white;
  border-radius: 10px;
  left: 20px;
  top: 150px;
  position: absolute;
  z-index: 5;
  width: 80px;
  height: 80px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Day = styled.span`
  font-size: 30px;
  font-family: Axiforma-light;
  color: ${colors.blue};
`;

export const Month = styled.span`
  font-size: 14px;
  color: ${colors.blue};
  text-transform: capitalize;
`;
export const Content = styled.div`
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
`;

export const CoverImage = styled.div`
    position:relative;
    width: 100%;
    background: #f3f3f3 url(${props => (props.blur)});
    background-size: cover;
    height: 250px;

    & img {
      opacity: 0;
      transition: opacity 0.5s ease;
        z-index: 2;
        width: 100%;
        object-fit: cover;
        height: 250px;
    }

    & img.loaded {
      opacity: 1;
    }

    & span {
      position: absolute;
      bottom: 20px;
      font-size: 8px;
      opacity: 0.8;
      right: 20px;
      color: #FFF;
    }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Name = styled.span`
  font-size: 20px;
  line-height: 22px;
  display: block;
  margin: 10px 0;
`;
export const OpeningTimesRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OpeningTime = styled.div`
  color: ${colors.darkgrey};
  font-family: "Axiforma-light";
  font-size: 15px;
`;
export const Type = styled.span`
  opacity: 0.3;
  display: block;
  font-size: 12px;
`;

export const LocationInfo = styled.span`
  font-size: 10px;
  opacity: 0.5;
  & > span:not(:first-child) {
    margin-left: 20px;
  }
`;

export const Address = styled.div`
  font-size: 10px;
  opacity: 0.5;
  display: flex;
  align-items: center;
  & > span {
    margin-bottom: -3px;
  }
`;

export const AddressIcon = styled(AddressSVG)`
  display: inline-block;
  width: ${props => props.size || "15px"};
  height: ${props => props.size || "15px"};
  margin-right: 5px;
`;

export const DistanceIcon = styled(PopulationSVG)`
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 5px;
`;

export const CloseBtn = styled(CloseSVG)`
  background-color: ${colors.grey};
  :hover {
    background-color: ${colors.yellow};
  }
  cursor: pointer;
  padding: 12px;
  width: 16px;
  height: 16px;
  border-radius: 20px;
  position: absolute;
  z-index: 5;
  right: 20px;
  top: 20px;
  fill: ${colors.darkblue};
`;

export const Description = styled.p`
  opacity: 0.5;
  font-size: 14px;
line-height: 1.6em;
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

export const ChartContainer = styled.div`
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  & .mapboxgl-ctrl-logo {
    display: none;
  }
`;

export const ChartContainerAddress = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
`;

export const Section = styled.div`
  padding: 20px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
`;

export const SectionTitle = styled.div`
  opacity: 0.7;
  font-size: 18px;
  margin-bottom: 20px;
`;

export const TitleAffix = styled.div`
  display: inline-block;
  opacity: 0.5;
  font-size: 14px;
  margin-left: 10px;
`;

export const CalendarEvents = styled.div`
  max-height: 300px;
  overflow-y: scroll;
  margin-top: 10px;
`;

export const ReadMore = styled.span`
  margin-left: 5px;
  color: ${colors.yellow};
  cursor: pointer;
`;
