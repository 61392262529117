import styled from "styled-components";
import colors from "../../helpers/colors";
import { ReactComponent as ArrowSVG } from "../../assets/icons/chevron.svg";
import { media } from "../../helpers/mediaQueries";

export const Container = styled.div`
  padding: 10px 30px;
  background-color: ${props => (props.blue ? colors.blue : colors.yellow)};
  border: 2px solid ${props => (props.blue ? colors.blue : colors.yellow)};
  color: ${props => (props.blue ? colors.white : colors.black)};
  width: fit-content;
  margin: ${props => (props.center ? "0 auto" : "0")}
  ${media.tablet`
    :hover {
      background-color: transparent;
      color ${props => (props.blue ? colors.blue : colors.black)};

    }
  `}
  border-radius: 25px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
`;

export const Label = styled.span`
  font-family: "Axiforma-semibold";
  font-weight: bold;
  margin-right: ${props => (props.spaceLeft ? 15 : 0)}px;
  font-size: 14px;
  min-height: 24px;
  line-height: 24px;
  margin-bottom: -1px;
  text-align: center;
`;

export const ArrowIcon = styled(ArrowSVG)`
  fill: ${colors.black};
  transform: rotate(${props => props.direction}deg);
  height: 16px;
  padding: 4px;
  width: 16px;
`;
