import styled from "styled-components";
import colors from "../../helpers/colors";

export const Container = styled.div`
  display: flex;
  cursor: pointer;
  :not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const DateField = styled.div`
  border: 1px solid ${colors.grey};
  width: 80px;
  height: 80px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Day = styled.span`
  font-size: 30px;
  font-family: Axiforma-light;
  color: ${colors.blue};
`;

export const Month = styled.span`
  font-size: 14px;
  color: ${colors.blue};
  text-transform: capitalize;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

export const Title = styled.span`
  font-size: 21px;
  font-family: Axiforma;
`;
