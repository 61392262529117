// Imports
// import rp from 'request-promise';
import axios from "axios";
const baseApiUrl = "https://staging.now.brussels/api";

/**
 * ApiCall
 * @param {*} options
 * @param {string} baseApiUrl
 */
export default function ApiCall(path, lang, options) {
  let params = "";
  Object.keys(options).forEach(key => {
    params = `${params}&${key}=${options[key]}`;
  });
  const req = {
    method: "GET",
    url: `${baseApiUrl}/${path}?lang=${lang}${params}`,
    crossDomain: true
  };
  req.json = true;
  return axios(req);
}
