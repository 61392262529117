import React, { useState, useEffect, useRef, useCallback } from "react";
import { createGlobalStyle } from "styled-components";
import { useCookies } from "react-cookie";

import {
    CategoryContext,
    OverlayContext,
    LanguageContext,
    PositionContext,
    DetailContext
} from "./contexts";
import "mapbox-gl/dist/mapbox-gl.css";
import { Container, OverlayMap } from "./App.style";
import Header from "./components/Header";
import Map from "./components/Map";
import LoadingScreen from "./views/LoadingScreen";
import LanguageSelectScreen from "./views/LanguageSelect";
import TutorialScreen from "./views/Tutorial";
import LocationScreen from "./views/LocationScreen";
import CookieScreen from "./views/CookieScreen";
import CategoryScreen from "./views/CategoryScreen";
import EventListScreen from "./views/PlacesScreen";
import languages, { checkForLanguage } from "./helpers/languages";
import { centerOfBrussels, isInBrussel } from "./helpers/checkLatLong";
import { Breakpoints } from "./helpers/mediaQueries";
import PlaceDetail from "./components/PlaceDetail";

const cookieLangString = "visit-brussels-now-lang";
const cookieLocationString = "visit-brussels-now-location-consent";
const cookieLastLocationString = "visit-brussels-now-last-location";
const cookieTutorialString = "visit-brussels-now-tutorial";
const cookieConsentString = "visit-brussels-now-cookie-consent";
const GlobalStyle = createGlobalStyle`
html {
    height: 100%;
    width: 100%;
}
body {
    height: 100%;
    margin: 0;
    width: 100%;
    font-family: Axiforma;
    & #root {
        height: 100%;
        width: 100%;
    }
}
a {
    text-decoration: none;
}
`;

function App() {
    const [cookies, setCookie] = useCookies([
        cookieLangString,
        cookieTutorialString,
        cookieConsentString,
        cookieLocationString,
        cookieLastLocationString
    ]);

    const dragOverride = useRef(false);

    const lastLocation = cookies[cookieLastLocationString] ? cookies[cookieLastLocationString] : centerOfBrussels;
    const [locationPermission, setLocationPermission] = useState(cookies[cookieLocationString] ? cookies[cookieLocationString] : null);
    const coords = useRef({latitude: lastLocation[0], longitude: lastLocation[1]})
    const firstime = useRef(true)
    const successfulRun = useRef(false)

    const _getLocationCall = useCallback(() => {
        const _LoadLocation = (position) => {
            let pos = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            }

            if (!successfulRun.current) {
                console.log('oke')
                successfulRun.current = true
                setLocationPermission(true)
            }

            if (!dragOverride.current) {
                if (pos.latitude.toFixed(4) !== coords.current.latitude.toFixed(4) || pos.longitude.toFixed(4) !== coords.current.longitude.toFixed(4)) {
                    console.log(pos, cookies[cookieLastLocationString])

                    coords.current = pos

                    if (pos && isInBrussel(pos.latitude, pos.longitude)) {
                        if (firstime.current) {
                            setFetchDataCoords({
                                latitude: pos.latitude,
                                longitude: pos.longitude
                            });

                            firstime.current = false
                        }

                        setPosition({
                            latitude: pos.latitude,
                            longitude: pos.longitude
                        });

                        setCookie(cookieLastLocationString, [pos.latitude, pos.longitude], {
                            path: "/",
                            maxAge: 365 * 24 * 60 * 60 * 1000
                        });

                        setInBrussel(true)
                    } else {
                        setInBrussel(false)
                    }
                }
            }
        }

        const _LocationBlocked = () => {
            if (!successfulRun.current) {
                console.log('blocked')
                setLocationPermission(false)
            }
        }

        if (navigator.geolocation) {
            navigator.geolocation.watchPosition(_LoadLocation, _LocationBlocked, {maximumAge:600000, timeout:5000, enableHighAccuracy: false});
        }
    })

    const isGeolocationEnabled = function () {
        navigator.geolocation.getCurrentPosition(
            function() {
                return true;
            },
            function (error) {
                return false;
            }, {maximumAge:600000, timeout:5000, enableHighAccuracy: false}
        );
    }

    const [overlay, setOverlay] = useState({
        places: window.innerWidth < Breakpoints.tablet ? null : true,
        menu: false
    });

    const [position, setPosition] = useState(coords.current);
    const [fetchDataCoords, setFetchDataCoords] = useState(coords.current);
    const [centerOverride, setCenterOverride] = useState(null);
    const [detailView, setDetailView] = useState(null);
    const [zoneCall, setZoneCall] = useState(null);
    const [inBrussel, setInBrussel] = useState(true);
    const [category, setCategory] = useState({ id: "all" });
    const [tutorialMode, setTutorialMode] = useState(cookies[cookieTutorialString] ? false : true);
    const [consentGiven, setConsentGiven] = useState(cookies[cookieConsentString] ? true : null);

    const [lang, setLang] = useState(cookies[cookieLangString] ? cookies[cookieLangString] : checkForLanguage(
        cookies[cookieLangString],
        (p, v, o) => {
            consentGiven && setCookie(p, v, o);
        }
    ));
    const [langSrc, setLangSrc] = useState(cookies[cookieLangString] ? languages[cookies[cookieLangString]] : languages[lang]);
    const [loaded, setLoaded] = useState(false);


    const showLocationScreen = ((navigator.geolocation !== null) && (navigator.geolocation !== undefined)) && locationPermission === null;

    useEffect(() => {
        locationPermission && _getLocationCall();
    }, [locationPermission, _getLocationCall]);

    // useEffect(() => {
    //     if (coords) {
    //         setPosition({
    //             latitude: coords.current.latitude,
    //             longitude: coords.current.longitude
    //         });
    //     }
    // }, [coords, setPosition]);
    // useEffect(
    //     () =>
    //     coords
    //     ? setInBrussel(isInBrussel(coords.current.latitude, coords.current.longitude))
    //     : setInBrussel(false),
    //     [coords, setInBrussel]
    // );
    //
    // useEffect(
    //     () => {
    //
    //
    //         console.log(lang, cookies[cookieLangString])
    //     }
    //     ,
    //     [consentGiven, cookies, setCookie]
    // );

    useEffect(
        () =>
        cookies[cookieLocationString]
        ? setLocationPermission(true)
        : setLocationPermission(null),
        [cookies]
    );
    useEffect(
        () =>
        cookies[cookieTutorialString]
        ? setTutorialMode(false)
        : setTutorialMode(true),
        [cookies]
    );
    useEffect(
        () =>
        cookies[cookieConsentString]
        ? setConsentGiven(true)
        : setConsentGiven(null),
        [cookies]
    );

    const isMobile = window.innerWidth < Breakpoints.tablet;

    return (
            <DetailContext.Provider value={{ detailView, setDetailView }}>
                <OverlayContext.Provider
                    value={{
                            overlay,
                            setOverlay
                        }}
                    >
                    <LanguageContext.Provider
                        value={{
                                lang,
                                setLang,
                                langSrc,
                                setLangSrc
                            }}
                        >
                        <CategoryContext.Provider value={{ category, setCategory }}>
                            <PositionContext.Provider
                                value={{
                                        position,
                                        setPosition,
                                        inBrussel,
                                        setInBrussel,
                                        fetchDataCoords,
                                        setFetchDataCoords,
                                        dragOverride,
                                        centerOverride,
                                        setCenterOverride,
                                        zoneCall,
                                        setZoneCall
                                    }}
                                >
                                <Container>
                                    <GlobalStyle />
                                    {!loaded && !tutorialMode && (
                                        <LoadingScreen callback={() => setLoaded(true)} />
                                    )}

                                    {detailView && isMobile && <PlaceDetail {...detailView} />}
                                    {loaded && (
                                        <Header
                                            hideMenu={tutorialMode || showLocationScreen || !lang}
                                            />
                                    )}
                                    {loaded && !lang && <LanguageSelectScreen />}
                                    {!loaded && tutorialMode && (
                                        <TutorialScreen
                                            callback={() => {
                                                consentGiven &&
                                                setCookie(cookieTutorialString, true, {
                                                    path: "/",
                                                    maxAge: 365 * 24 * 60 * 60 * 1000
                                                });
                                                setLoaded(true);
                                                setTutorialMode(false);
                                            }}
                                            />
                                    )}
                                    {loaded && !tutorialMode && consentGiven === null && (
                                        <CookieScreen
                                            setConsentGiven={p => {
                                                if (p === true) {
                                                    setCookie(cookieTutorialString, true, {
                                                        path: "/",
                                                        maxAge: 365 * 24 * 60 * 60 * 1000
                                                    });
                                                    setCookie(cookieConsentString, true, {
                                                        path: "/",
                                                        maxAge: 365 * 24 * 60 * 60 * 1000
                                                    });
                                                }
                                                setConsentGiven(p);
                                            }}
                                            />
                                    )}
                                    {loaded &&
                                        consentGiven !== null &&
                                        !tutorialMode &&
                                        showLocationScreen && (
                                            <LocationScreen
                                                setLocation={p => {
                                                    consentGiven &&
                                                    setCookie(cookieLocationString, true, {
                                                        path: "/",
                                                        maxAge: 365 * 24 * 60 * 60 * 1000
                                                    });
                                                    setLocationPermission(p);
                                                }}
                                            />
                                    )}

                                    {(!loaded || tutorialMode || consentGiven === null || showLocationScreen) && <OverlayMap />}

                                    <CategoryScreen />

                                    {loaded && !tutorialMode && !showLocationScreen && (
                                        <EventListScreen />
                                    )}
                                    <Map
                                        locationPermission={locationPermission}
                                        overlayActive={overlay}
                                        loaded={loaded && !tutorialMode}
                                        tutorial={
                                            tutorialMode ||
                                            showLocationScreen ||
                                            !loaded ||
                                            consentGiven === null
                                        }
                                    />
                            </Container>
                        </PositionContext.Provider>
                    </CategoryContext.Provider>
                </LanguageContext.Provider>
            </OverlayContext.Provider>
        </DetailContext.Provider>
    );
}

export default App;
