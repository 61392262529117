/* eslint-disable import/prefer-default-export */
import styled, { keyframes } from "styled-components";
import { ReactComponent as ResetSVG } from "../../assets/icons/location.svg";
import { ReactComponent as PlusSVG } from "../../assets/icons/plus.svg";
import { ReactComponent as MinusSVG } from "../../assets/icons/minus.svg";
import colors from "../../helpers/colors";
import brussel from "../../assets/images/brussel.png";
import { Container as Button } from "../../components/Button/button.style";
import { media } from "../../helpers/mediaQueries";

const appear = (from, to) => keyframes`
  from {
    opacity: ${from};
  }

  to {
    opacity: ${to};
  }
`;
export const OverlayMap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(${brussel});
  background-size: cover;
  background-position: center;
  right: 0;
  left: 0;
  z-index: 20;
`;

export const CategoriesContainer = styled.div`
  z-index: 2;
  top: 100px;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  ${media.tablet`
    width: fit-content;
    bottom: 30px;
    right: 120px;
    top: initial;
  `}
`;

export const Categories = styled.div`
  background-color: ${colors.white};
  padding: 10px;
  border-radius: 20px;
  display: flex;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.05);
`;

export const Category = styled.div`
  display: flex;
  align-items: center;
  opacity: ${props => (props.active) ? 1 : 0.3 }
  cursor: pointer;
  transition: opacity 200ms ease-in-out;
  user-select: none;
  & span {
      margin-bottom: -2px;
      line-height: 1;
  }
  &:hover div {
    transform: scale(1.15);
  }
  &:not(:last-child) {
    margin-right: 15px;
  }
`;

export const CategoryMarker = styled.div`
  background-color: ${props => props.color};
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-right: 5px;
  transition: transform 180ms ease-in-out;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  & .mapboxgl-ctrl-logo {
    display: none;
  }
  opacity: ${props => (props.tutorial ? 0.6 : 1)};

  &:before {
    opacity: ${props => (props.dark ? 0.2 : 0)};
    ${media.tablet`
    opacity: 0;
  `}
    z-index: 1;
    position: absolute;
    pointer-events: none;
    content: "";
    width: 100%;
    height: 100%;
    background-color: ${colors.black};
    animation: ${props => (props.dark ? appear(0, 0.2) : appear(0.2, 0))} 0.4s
      ease-in;
    ${media.tablet`
    animation: none;
  `}
  }
`;

export const ButtonContainer = styled.div`
  pointer-events: none;
  z-index: 1;
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  ${Button} {
    pointer-events: all;
  }
  ${media.tablet`
    display: none;
  `}
`;
export const MapButtonsContainer = styled.div`
  display: flex;
  z-index: 2;
  position: absolute;
  flex-direction: column;
  overflow: visible;
  bottom: 150px;
  right: 20px;
  ${media.tablet`
    bottom: 20px;
  `}
`;

export const ZoomInButton = styled(PlusSVG)`
  padding: 20px;
  height: 20px;
  fill: white;
  cursor: pointer;
  width: 20px;
  border-radius: 50px;
  background-color: ${colors.yellow};
  opacity: ${props => (props.disabled ? 0.3 : 1)};
  overflow: visible;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15), 0 10px 20px 0 rgba(0, 0, 0, 0.14);
  display: none;
  margin-bottom: 20px;
  transition: background 180ms ease-in-out;
  &:hover {
    background-color: #e2b406
  }
  ${media.tablet`
    display: block;
  `}
`;
export const ZoomOutButton = styled(MinusSVG)`
  padding: 20px;
  height: 20px;
  fill: white;
  cursor: pointer;
  width: 20px;
  margin-top: 20px;
  border-radius: 50px;
  background-color: ${colors.yellow};
  opacity: ${props => (props.disabled ? 0.3 : 1)};
  overflow: visible;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15), 0 10px 20px 0 rgba(0, 0, 0, 0.14);
  display: none;
  transition: background 180ms ease-in-out;
  &:hover {
    background-color: #e2b406
  }
  ${media.tablet`
    display: block;
  `}
`;
export const ReOrientButton = styled(ResetSVG)`
  padding: 20px;
  height: 20px;
  fill: white;
  cursor: pointer;
  width: 20px;
  overflow: visible;
  transition: background 180ms ease-in-out;
  &:hover {
    background-color: #1e306e
  }

  border-radius: 50px;
  background-color: ${colors.blue};
  opacity: ${props => (props.disabled ? 0.3 : 1)};
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15), 0 10px 20px 0 rgba(0, 0, 0, 0.14);
`;

const pulse = opacity => keyframes`
  from {
    transform: scale(0);
    opacity: ${opacity[0]};
  }

  to {
    transform: scale(1.5);
    opacity: ${opacity[1]};
  }
`;

export const CurrentLocation = styled.div`
  background-color: ${colors.darkestgrey};
  width: 10px;
  height: 10px;
  border-radius: 10px;
  position: relative;
  :before {
    position: absolute;
    z-index: -1;
    top: -15px;
    left: -15px;
    content: "";
    background-color: ${colors.blue};
    opacity: 0.4;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    animation: ${pulse([0.4, 0])} 3s ease-in infinite;
  }
  :after {
    position: absolute;
    z-index: -1;
    top: -5px;
    left: -5px;
    content: "";
    background-color: ${colors.white};
    width: 20px;
    height: 20px;
    border-radius: 40px;
    animation: ${pulse([1, 0])} 3s ease-in infinite;
  }
`;
