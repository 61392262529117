import React, { useContext, useState, Fragment, useEffect, useRef, useCallback } from "react";
import ReactMapGL, { Marker } from "react-map-gl";

import {
    LanguageContext,
    OverlayContext,
    PositionContext,
    DetailContext
} from "../../contexts";
import {
    Container,
    ButtonContainer,
    ReOrientButton,
    MapButtonsContainer,
    ZoomInButton,
    ZoomOutButton,
    CurrentLocation,
    Categories,
    CategoriesContainer,
    Category,
    CategoryMarker
} from "./map.style";
import { getZones } from "../../api/zones";
import colors from "../../helpers/colors";
import Button from "../../components/Button";
import {
    checkLatitude,
    checkLongitude,
    centerOfBrussels
} from "../../helpers/checkLatLong";
import { sendEvent } from "../../helpers/gtm";
import { Breakpoints } from "../../helpers/mediaQueries";
import UserLocationDenied from "../../components/UserLocationDenied";

const maxZoom = 24;
const defaultZoom = 16;
const minZoom = 12;


const Map = ({ locationPermission, tutorial, loaded, overlayActive, onClick }) => {
    const { overlay, setOverlay } = useContext(OverlayContext);
    const { setDetailView } = useContext(DetailContext);
    const { langSrc } = useContext(LanguageContext);
    const {
        position,
        inBrussel,
        setFetchDataCoords,
        centerOverride,
        dragOverride,
        setZoneCall
    } = useContext(PositionContext);

    const [zoom, setZoom] = useState(defaultZoom);
    const [initial, setInitial] = useState(true);
    const [openPopup, setOpenPopup] = useState(false);
    const [timer, setTimer] = useState(null);
    const [draggingtimer, setDraggingTimer] = useState(null);
    const [mapCoords, setMapCoords] = useState({
        width: 400,
        height: 400,
        zoom: defaultZoom,
        latitude: position.latitude,
        longitude: position.longitude,
        ...position
    });


    const isMobile = window.innerWidth < Breakpoints.tablet;
    const from = overlayActive ? "50" : "-100";
    const to = overlayActive ? "-100" : "50";

    useEffect(() => {
        if (position) {
            if (initial) {
                setMapCoords({...mapCoords, ...position});
                setInitial(false);
            }

            if (position.latitude !== mapCoords.latitude || position.longitude !== mapCoords.longitude) {
                setMapCoords({...mapCoords, ...position});
            }
        }

    }, [initial, position]);

    useEffect(() => {
        if (centerOverride) {
            setMapCoords({...mapCoords, ...centerOverride});
        }
    }, [centerOverride]);

    const mapRef = useRef(null);
    const [firstrun, setFirstrun] = useState(false);
    const polygons = useRef([]);

    const spotColors = {
        1.3: "rgba(252, 244, 165, 0.7)",
        1.2: "rgba(255, 226, 131, 0.7)",
        1.1: "rgba(252, 200, 16, 0.7)",
        2.3: "rgba(54, 74, 190, 0.6)",
        2.2: "rgba(54, 74, 190, 0.7)",
        2.1: "rgba(54, 74, 190, 0.8)"
    };

    let animation
    let current = 1
    let amount = 3

    const _createPolygonLayers = () => {
        const map = mapRef.current.getMap();

        Object.keys(polygons.current).forEach(key => {
            if (!map.getLayer(key)) {
                map.addLayer({
                    id: `L1.${key}`,
                    type: "fill",
                    source: {
                        type: "geojson",
                        data: {
                            type: "FeatureCollection",
                            features: []
                        }
                    },
                    layout: {},
                    paint: {
                        "fill-color": spotColors[key],
                    }
                }, 'L2.1');
            }
        });

        // setInterval(_loadNewPolygonData, 5 * 1000)//) * 60)
    }

    const _showPolygon = () => {
        let stillAnimate = _updatePolygonData();

        if (stillAnimate) {
            setTimeout(() => {
                animation = window.requestAnimationFrame(_showPolygon);
            }, 100)
        }
    }

    const _updatePolygonData = () => {
        let stillAnimate = false
        const map = mapRef.current.getMap();

        Object.keys(polygons.current).forEach(key => {
            const layer = polygons.current[key],
                  newlayer = []

            Object.keys(layer).forEach((a, k) => {
                const v = layer[a]

                if (k <= (current * amount)) {
                    newlayer.push(v)
                }
            })

            if (newlayer.length < Object.keys(layer).length) {
                stillAnimate = true
            }

            map.getSource(`L1.${key}`).setData({
                type: "FeatureCollection",
                features: newlayer
            });
        });


        current++

        return stillAnimate
    };

    const _onClick = e => {
        if (e.features[0]) {
            const layer = e.features[0].layer.id;

            if (layer.indexOf('L1.') !== -1) {
                const loc = e.lngLat;
                setZoneCall(true);
                setFetchDataCoords({
                    latitude: loc[1],
                    longitude: loc[0]
                });
                setOverlay({ menu: false, places: true });
            }
            if (layer === "L2.1") {
                const placeId = e.features[0].properties.id;
                if (placeId) {
                    setDetailView({id: placeId});
                    !isMobile && setOverlay({ menu: false, places: true });
                }
            }
        }
    }

    useEffect(() => {
        if (mapRef) {
            const map = mapRef.current.getMap();
            map.on("load", () => {
                getZones().then(({data}) => {
                    polygons.current = data.data

                    _createPolygonLayers()

                    setTimeout(_showPolygon, 4000)
                });
            });
        }
    }, [mapRef]);

    // const _onMouseEnter = e => {
    //     const map = mapRef.current.getMap();
    //
    //     if (hoveredStateId) {
    //         map.setFeatureState({source: 'L2.1', id: hoveredStateId}, { size: 1 });
    //     }
    //
    //     hoveredStateId = map.queryRenderedFeatures(e.point)[0].id;
    //     map.setFeatureState({source: 'L2.1', id: hoveredStateId}, { size: 10 });
    // }
    //
    // const _onMouseLeave = e => {
    //     if (hoveredStateId) {
    //         const map = mapRef.current.getMap();
    //
    //         map.setFeatureState({source: 'L2.1', id: hoveredStateId}, { size: 1 });
    //     }
    //
    //     hoveredStateId =  null;
    // }

    //Layer Opacity toggle
    const [activeLayer, setActiveLayer] = useState({1: 1, 2: 1});

    const _ChangeLayers = useCallback(
        e => {
            const map = mapRef.current.getMap();
            const reverse = activeLayer[e] === 1 ? 0 : 1

            map.setPaintProperty(`L1.${e}.1`, 'fill-opacity', reverse)
            map.setPaintProperty(`L1.${e}.2`, 'fill-opacity', reverse)
            map.setPaintProperty(`L1.${e}.3`, 'fill-opacity', reverse)

            setActiveLayer({...activeLayer, [e]: reverse})
        },
        [mapRef, activeLayer, setActiveLayer]
    );

    const _onViewportChange = useCallback(
        v => {
            setZoom(v.zoom);
            if (v.latitude > 0) {
                setMapCoords({
                    ...mapCoords,
                    latitude: checkLatitude(v.latitude),
                    longitude: checkLongitude(v.longitude)
                });

                if (firstrun) {
                    dragOverride.current = true

                    clearTimeout(draggingtimer);
                    setDraggingTimer(
                        setTimeout(() => { dragOverride.current = false }, 25000)
                    );
                } else {
                    setFirstrun(true)
                }

                clearTimeout(timer);
                setTimer(
                    setTimeout(
                        () =>
                        setFetchDataCoords({
                            latitude: v.latitude,
                            longitude: v.longitude
                        }),
                        500
                    )
                );
            }
        },
        [setFetchDataCoords, timer, mapCoords, firstrun]
    )

    const zoomIn = useCallback(() => {
        zoom !== maxZoom && setZoom(z => z + 1);
    }, [zoom]);

    const zoomOut = useCallback(() => {
        zoom !== minZoom && setZoom(z => z - 1);
    }, [zoom]);

    const reCenter = useCallback(() => {
        if (!locationPermission) {
            setOpenPopup(true)
        } else if (inBrussel && position) {
            //Force relocation
            dragOverride.current = false

            setMapCoords({...mapCoords, ...position});
            setZoom(defaultZoom);
            setFetchDataCoords(position);
        }
    }, [inBrussel, position, setFetchDataCoords, mapCoords, locationPermission]);

    const openPlaces = useCallback(() => {
        sendEvent("DiscoverNow", "DiscoverNow", "");
        setOverlay({ menu: false, places: true });
    }, [setOverlay]);

    return (
        <Container dark={overlay.places} tutorial={tutorial}>
            {!tutorial && (
                <CategoriesContainer>
                    <Categories>
                        <Category active={activeLayer[2]} onClick={() => _ChangeLayers(2)}>
                            <CategoryMarker color={colors.blue} />
                            <span>{langSrc.visitors}</span>
                        </Category>
                        <Category active={activeLayer[1]} onClick={() => _ChangeLayers(1)}>
                            <CategoryMarker color={colors.yellow} />
                            <span>{langSrc.locals}</span>
                        </Category>
                    </Categories>
                </CategoriesContainer>

            )}
            <ReactMapGL
                ref={mapRef}
                {...mapCoords}
                zoom={zoom}
                onClick={_onClick}
                interactiveLayerIds={["L1.1.1", "L1.1.2", "L1.1.3", "L1.2.1", "L1.2.2", "L1.2.3", "L2.1"]}
                minZoom={minZoom}
                width="100%"
                attributionControl={false}
                height="100%"
                mapStyle="mapbox://styles/arnovanbiesen/cjxlj64rp05wq1cqlavjrsw8r"
                onViewportChange={_onViewportChange}
                mapboxApiAccessToken="pk.eyJ1IjoiYXJub3ZhbmJpZXNlbiIsImEiOiJjanhkMnoyMmswMHhpM3pwMHFlcDZydjBrIn0.snmC_w-aD_y0CCR9xyzDrg"
            >
                {!tutorial && inBrussel && locationPermission && (
                    <Marker
                        latitude={position.latitude}
                        longitude={position.longitude}
                        offsetLeft={-20}
                        offsetTop={-10}
                        >
                        <CurrentLocation />
                    </Marker>
                )}
            </ReactMapGL>

            {loaded && !tutorial && (
                <Fragment>
                    <MapButtonsContainer>
                        <ZoomInButton disabled={zoom === 24} onClick={zoomIn} />
                        <ReOrientButton
                            disabled={!inBrussel || !locationPermission}
                            from={from - 40}
                            to={to - 40}
                            onClick={reCenter}
                        />
                        <ZoomOutButton disabled={zoom === 12} onClick={zoomOut} />
                    </MapButtonsContainer>

                    <ButtonContainer>
                        <Button
                            arrow="up"
                            label={langSrc.discoverNowCTA}
                            callback={openPlaces}
                            />
                    </ButtonContainer>
                </Fragment>
            )}

            {openPopup && (
                <UserLocationDenied />
            )}
        </Container>
    );
};

export default Map;
