import styled from "styled-components";
import colors from "../../helpers/colors";
import { ReactComponent as ArrowSVG } from "../../assets/icons/chevron.svg";
import { media } from "../../helpers/mediaQueries";
import offlinebg from "../../assets/images/offlinebg.png";


export const TitleLine = styled.div`
  height: 3px;
  width: 100px;
  border-radius: 10px;
  background-color: ${colors.yellow};
  margin-bottom: 20px;
`;

export const Text = styled.div`
  width: 65%;
  font-family: Axiforma-light;
   font-size: 14px;
   opacity: 0.5;
`;

export const Title = styled.div`
  width: 65%;
  font-family: "Axiforma";
  font-size: 20px;
  display: flex;
  align-items: center;
  line-height: 22px;
`;

export const NoFound = styled.div`
  margin-top: 30px;
  color: ${colors.yellow};
  & > div {
      margin-top: 20px;
  }
`;

export const Container = styled.div`
  position: absolute;
  pointer-events: none;
  z-index: ${props => (props.open ? 4 : 3)};
  height: calc(100% - 80px);
  width: 100%;
  bottom: ${props => props.to}%;
  display: flex;
  opacity: ${props => (props.hide ? 0 : 1)};
  align-items: center;
  justify-content: center;
  flex-direction: row;
  transition: bottom 0.3s ease-in;

  ${media.tablet`
    justify-content: flex-start;
    height: 100%;
    bottom: ${props => (props.to === "0" ? 0 : `calc(${props.to}% + 400px)`)};
  `}
`;

export const LateInBrussels = styled.div`
  margin-top: 20px;
  padding: 25px 20px;
  border-top: 1px solid #ffd252;
  border-bottom: 1px solid #ffd252;
  text-align: center
`;

export const Card = styled.div`
  pointer-events: all;
  background: ${props => (props.height ? `#FFF url(${offlinebg})` : colors.white)};
  background-position: center right;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: ${props => (props.height ? '10px' : '10px 10px 0 0')};
  padding: ${props => (props.height ? '20px' : '0')};

  margin: 0 20px;
  margin-top: 20px;

  max-width: calc(100% - 40px);
  width: 100%;
  height: ${props => (props.height ? 'auto' : 'calc(100% - 180px)')};
  position: relative;
  box-sizing: border-box;
  ${media.tablet`
    width: 400px;
    margin-top: ${props => (props.height ? '100px' : '20px')};
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15), 0 10px 20px 0 rgba(0, 0, 0, 0.14);
  `}
`;

export const Icon = styled.div`
  height: 80px;
  background-image: url(${props => props.src});
  background-position: center;
  width: 90px;
  background-size: cover;
  margin: 0 auto 20px;
`;

export const Placeholder = styled.div`
    padding: 20px 0 10px;
    border-bottom: 1px solid #f5f5f5;

    svg {
      max-width: 345px;
    }
`;

export const DetailCard = styled.div`
  pointer-events: all;
  background-color: ${colors.white};
  border-radius: 0;
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  z-index: 10;
  padding-bottom: 0;
  box-sizing: border-box;

  ${media.tablet`
  border-radius: 10px 10px 0 0;
    display: block;
    position: relative;
    margin: 0 20px;
    height: calc(100% - 100px);
  margin-top: 100px;

    width: 400px;
  `}
  box-shadow: 0 4px 6px 0 rgba(0,0,0,0.15), 0 10px 20px 0 rgba(0,0,0,0.14);
`;

export const Content = styled.div`
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  scroll-behavior: smooth;
`;

export const BackButton = styled.div`
  color: ${colors.blue};
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 10px 0;
`;

export const CloseBtn = styled(ArrowSVG)`
  background-color: ${colors.grey};
  :hover {
    background-color: ${colors.yellow};
  }
  cursor: pointer;
  /* padding: 10px; */
  transform: ${props => (props.open ? `rotate(270deg)` : `rotate(90deg)`)};
  width: 16px;
  height: 16px;
  padding: 12px;
  z-index: 5;
  border-radius: 20px;
  position: absolute;
  right: 20px;
  top: 20px;
  fill: ${colors.darkblue};
`;
