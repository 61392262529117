import call from "./apicall";

/**
 * gets places
 * @constructor
 */
export const getPlaces = ({
  lang,
  latitude,
  longitude,
  filterId,
  userLat,
  userLng
}) => {
  if (process.env.NODE_ENV === "development") {
    console.info("API: GETPLACES", { lang, latitude, longitude, filterId });
  }
  const body = {
    lat: latitude,
    lng: longitude,
    userlat: userLat,
    userlng: userLng
  };
  if (filterId) {
    body.filter = filterId;
  }
  return call("list", lang === "es" ? "sp" : lang, body);
};

export const getPlacesByZone = ({
  lang,
  latitude,
  longitude,
  filterId,
  userLat,
  userLng
}) => {
  if (process.env.NODE_ENV === "development") {
    console.info("API: GETPLACESINZONE", { lang, latitude, longitude, filterId });
  }
  const body = {
    lat: latitude,
    lng: longitude,
    userlat: userLat,
    userlng: userLng
  };
  if (filterId) {
    body.filter = filterId;
  }
  return call("zone", lang === "es" ? "sp" : lang, body);
};

/**
 * gets place detail
 * @constructor
 */
export const getPlaceDetail = ({ id, lang }) => {
  if (process.env.NODE_ENV === "development") {
    console.info("API: GETPLACEDETAIL", { id, lang });
  }

  return call("detail", lang === "es" ? "sp" : lang, { id: id });
};
