import React, { useContext, useState, Fragment, useEffect } from "react";
import {
  Container,
  Content,
  SlideContainer,
  SlideContext,
  Categories,
  Themes,
  Menu,
  MenuItems,
  MenuItem,
  IndicatorContainer,
  Indicator,
  Line,
  CategoryFooter,
  DesktopCategoryFooter,
  ChangeLanguage,
  ChangeLanguageContainer,
  ChangeLanguageCard,
  Languages,
  Language,
  LangDivider,
  PoweredByLogo
} from "./categoryScreen.style";
import {
  CategoryContext,
  OverlayContext,
  LanguageContext
} from "../../contexts";
import CategoryItem from "../../components/CategoryItem";
import ThemeItem from "../../components/ThemeItem";
import InfluencerItem from "../../components/InfluencerItem";
import languageSources, { cookieLangString } from "../../helpers/languages";
import { useCookies } from "react-cookie";

import allSVG from "../../assets/icons/allcategories.svg";
import { getCategories } from "../../api/categories";
import { sendEvent } from "../../helpers/gtm";

function renderThemeItem(
  { name, id, icon, short, description },
  onClick,
  isActive
) {
  return (
    <ThemeItem
      key={id}
      isActive={isActive}
      icon={icon}
      title={name}
      subtitle={short}
      description={description}
      onClick={onClick}
    />
  );
}
function renderInfluencerItem(
  { name, id, image, short, description },
  onClick,
  isActive
) {
  return (
    <InfluencerItem
      key={id}
      isActive={isActive}
      image={image}
      title={name}
      short={short}
      description={description}
      onClick={onClick}
    />
  );
}

const swipeThreshold = 100;

function getDirection(e, start, pos) {
  let newPos = pos;
  const end = e.changedTouches[0].clientX;
  if (end > start) {
    const diff = end - start;
    if (diff > swipeThreshold) {
      newPos--;
    }
  } else {
    const diff = start - end;
    if (diff > swipeThreshold) {
      newPos++;
    }
  }
  if (newPos >= 0 && newPos <= 2) {
    return newPos;
  }
  return pos;
}

const slideParams = [0, 33.33, 66.66];
const contentParams = [0, -100, -200];

const CategoryView = () => {
  const [cookies, setCookie] = useCookies([cookieLangString]); //eslint-disable-line
  const { category, setCategory } = useContext(CategoryContext);

  const { overlay, setOverlay } = useContext(OverlayContext);
  const { langSrc, lang, setLang, setLangSrc } = useContext(LanguageContext);
  const [activeTab, setActiveTab] = useState(0);
  const [langSelectOpen, setLangSelectOpen] = useState(false);
  const [startSwipe, setStartSwipe] = useState(0);
  const [sliderPos, setSliderPos] = useState([0, 0]);
  const [contentPos, setContentPos] = useState([0, 0]);
  const [fromTo, setFromTo] = useState(["0", "-100"]);
  const [influencers, setInflueners] = useState(null);
  const [categories, setCategories] = useState(null);
  const [themes, setThemes] = useState(null);

  const updatePosition = newPos => {
    setSliderPos([slideParams[activeTab], slideParams[newPos]]);
    setContentPos([contentParams[activeTab], contentParams[newPos]]);
    setActiveTab(newPos);
  };
  const updateLanguage = l => {
    sendEvent("Language", l, "");
    setLangSelectOpen(false);
    setLang(l);
    setLangSrc(languageSources[l]);
    setCookie(cookieLangString, l, {
      path: "/",
      maxAge: 365 * 24 * 60 * 60 * 1000
    });
  };
  useEffect(() => {
    setActiveTab(0);
    setContentPos([0, 0]);
    setSliderPos([0, 0]);
    setFromTo([overlay.menu ? "-100" : "0", overlay.menu ? "0" : "-100"]);
  }, [overlay]);
  useEffect(() => {
    overlay.menu &&
      getCategories(lang).then(r => {
        setCategories(r.data.data.categories);
        setInflueners(r.data.data.influencers);
        setThemes(r.data.data.themes);
      });
  }, [overlay, lang]);
  const categoryFooter = (
    <Fragment>
      <a
        href={`https://visit.brussels/${lang}/article/cookies-policy`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <PoweredByLogo />
      </a>
      <ChangeLanguageContainer>
        <ChangeLanguage
          isOpen={langSelectOpen}
          onClick={() => setLangSelectOpen(!langSelectOpen)}
        >
          {lang}
        </ChangeLanguage>{" "}
        {langSelectOpen && (
          <ChangeLanguageCard>
            <Languages>
              <Language
                active={lang === "fr"}
                onClick={() => updateLanguage("fr")}
              >
                Francais
              </Language>
              <LangDivider />
              <Language
                active={lang === "nl"}
                onClick={() => updateLanguage("nl")}
              >
                Nederlands
              </Language>
              <LangDivider />
              <Language
                active={lang === "en"}
                onClick={() => updateLanguage("en")}
              >
                English
              </Language>
              <LangDivider />
              <Language
                active={lang === "es"}
                onClick={() => updateLanguage("es")}
              >
                Español
              </Language>
            </Languages>
          </ChangeLanguageCard>
        )}
      </ChangeLanguageContainer>
    </Fragment>
  );
  return (
    <Container to={fromTo[1]} from={fromTo[0]}>
      <Menu>
        <MenuItems>
          <MenuItem
            third={influencers && influencers.length !== 0}
            onClick={() => updatePosition(0)}
            active={activeTab === 0}
          >
            {langSrc.categories}
          </MenuItem>
          <MenuItem
            third={influencers && influencers.length !== 0}
            onClick={() => updatePosition(1)}
            active={activeTab === 1}
          >
            {langSrc.themes}
          </MenuItem>
          {influencers && influencers.length !== 0 && (
            <MenuItem
              onClick={() => updatePosition(2)}
              active={activeTab === 2}
            >
              {langSrc.influencers}
            </MenuItem>
          )}
        </MenuItems>
        <IndicatorContainer>
          <Indicator
            third={influencers && influencers.length !== 0}
            from={sliderPos[0]}
            to={
              sliderPos[1] === 33.33 && influencers && influencers.length === 0
                ? 50
                : sliderPos[1]
            }
          />
          <Line />
        </IndicatorContainer>
      </Menu>
      <Content
        onTouchStart={e => setStartSwipe(e.changedTouches[0].clientX)}
        onTouchEnd={e => updatePosition(getDirection(e, startSwipe, activeTab))}
      >
        <SlideContainer from={contentPos[0]} to={contentPos[1]}>
          <SlideContext>
            <Categories>
              <CategoryItem
                key="all"
                isActive={category.id === "all"}
                name={langSrc.allCategories}
                icon={allSVG}
                onClick={() => {
                  sendEvent("Menu", "Category", "All Categories");
                  category.id !== "all" &&
                    setOverlay({ menu: false, places: true });
                  category.id !== "all" &&
                    setCategory({
                      id: "all",
                      icon: allSVG,
                      type: "all"
                    });
                }}
              />
              {categories &&
                categories.map(c => (
                  <CategoryItem
                    key={c.name}
                    isActive={category.id === c.id}
                    name={c.short}
                    icon={c.icon}
                    onClick={() => {
                      sendEvent("Menu", "Category", c.short);
                      category.id !== c.id &&
                        setOverlay({ menu: false, places: true });
                      category.id !== c.id
                        ? setCategory({
                            id: c.id,
                            icon: c.icon,
                            type: c.type
                          })
                        : setCategory({ key: "all" });
                    }}
                  />
                ))}
              <CategoryFooter>{categoryFooter}</CategoryFooter>
            </Categories>
          </SlideContext>
          <SlideContext>
            <Themes>
              {themes &&
                themes.map(t =>
                  renderThemeItem(
                    t,
                    () => {
                      sendEvent("Menu", "Theme", t.name);
                      category.id !== t.id &&
                        setOverlay({ menu: false, places: true });
                      category.id !== t.id
                        ? setCategory({
                            id: t.id,
                            value: t.short,
                            type: "theme",
                            icon: t.icon
                          })
                        : setCategory({ key: "all" });
                    },
                    t.id === category.id
                  )
                )}
            </Themes>
          </SlideContext>
          <SlideContext>
            <Themes>
              {influencers &&
                influencers.map(t =>
                  renderInfluencerItem(
                    t,
                    () => {
                      sendEvent("Menu", "Influencer", t.name);

                      category.id !== t.id &&
                        setOverlay({ menu: false, places: true });
                      category.id !== t.id
                        ? setCategory({
                            id: t.id,
                            value: t.name,
                            type: "influencer",
                            icon: t.image
                          })
                        : setCategory({ key: "all" });
                    },
                    t.id === category.id
                  )
                )}
            </Themes>
          </SlideContext>
        </SlideContainer>
      </Content>
      <DesktopCategoryFooter>{categoryFooter}</DesktopCategoryFooter>
    </Container>
  );
};

export default CategoryView;
