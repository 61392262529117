import styled from "styled-components";
import brussel from "./assets/images/brussel.png";

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const OverlayMap = styled.div`
  width: 104%;
  height: 104%;
  position: absolute;
  background-image: url(${brussel});
  background-size: cover;
  background-color: white;
  background-position: center;
  right: 0;
  left: 0;
  z-index: 20;
  transform: translate(-2%,-3.4%);

  @media (min-aspect-ratio: 1/1) {
    width: 102%;
    height: 105%;
    transform: translate(-1%, -4.4%);
  }
`;
