import React from "react";
import { string, func, bool } from "prop-types";
import {
  Container,
  Image,
  Content,
  Title,
  SubTitle,
  Description
} from "./influencerItem.style";
const InfluencerItem = ({
  title,
  image,
  subtitle,
  description,
  onClick,
  isActive
}) => (
  <Container isActive={isActive} onClick={onClick}>
    <Image image={image} />
    <Content>
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
      <Description>{description}</Description>
    </Content>
  </Container>
);

InfluencerItem.propTypes = {
  title: string.isRequired,
  subtitle: string.isRequired,
  description: string.isRequired,
  image: string.isRequired,
  onClick: func.isRequired,
  isActive: bool.isRequired
};

export default InfluencerItem;
