import React, { useContext, useState, useEffect, useCallback } from "react";
import {
    Container,
    Card,
    DetailCard,
    Title,
    TitleLine,
    NoFound,
    LateInBrussels,
    Content,
    CloseBtn,
    Text,
    Icon
} from "./placesScreen.style";
import {
    OverlayContext,
    LanguageContext,
    CategoryContext,
    PositionContext,
    DetailContext
} from "../../contexts";
import EventItem from "../../components/PlaceItem";
import { getPlaces, getPlacesByZone } from "../../api/places";
import Button from "../../components/Button";
import { Breakpoints } from "../../helpers/mediaQueries";
import { centerOfBrussels } from "../../helpers/checkLatLong";
import PlaceDetail from "../../components/PlaceDetail";
import moon from "../../assets/icons/moon.svg";
import {
    BigPlaceholder,
    MediumPlaceholder,
    SmallPlaceholder
} from "./placeHolders";

import { Info } from "./../CookieScreen/cookieScreen.style";

function renderEventItem(
    {
        id,
        name,
        size = "large",
        category,
        location = "location",
        distance,
        images,
        last
    },
    setDetailView,
    index,
    inBrussel
) {
    return (
        <EventItem
            key={`${id}-${index}`}
            id={id}
            name={name}
            type={category}
            image={images.image}
            size={size}
            distanceTo={inBrussel ? distance : null}
            location={location}
            setDetailView={setDetailView}
            last={last}
            lazy={index > 5}
            />
    );
}

function RenderPlaceholders() {
    return [0, 0, 0, 0, 0, 0, 0, 0].map((x, i) => {
        switch (x) {
            case 0:
            return <BigPlaceholder key={`big-${i}`} />;
            default:
            case 1:
            return <MediumPlaceholder key={`med-${i}`} />;
            case 2:
            return <SmallPlaceholder key={`sml-${i}`} />;
        }
    });
}

const getTitle = (category, langSrc) => {
    switch (category.type) {
        case "toeat":
        return langSrc.toEatCatchphrase;
        case "todo":
        return langSrc.toDoCatchphrase;
        case "todrink":
        return langSrc.toDrinkCatchphrase;
        case "influencer":
        return langSrc.influencersCatchphrase.replace(
            "{{influencer}}",
            category.value
        );
        case "theme":
        return category.value;
        default:
        return langSrc.placesDefaultText;
    }
};

const EventListScreen = () => {
    const { langSrc, lang } = useContext(LanguageContext);
    const { overlay, setOverlay } = useContext(OverlayContext);
    const { category } = useContext(CategoryContext);
    const { position,
        setFetchDataCoords,
        setCenterOverride,
        fetchDataCoords,
        inBrussel,
        dragOverride,
        zoneCall,
        setZoneCall
    } = useContext(PositionContext);
    const filterId = category.id || null;
    const { detailView, setDetailView } = useContext(DetailContext);
    const [currentRequest, setCurrentRequest] = useState({
        coords: {
            longitude: null,
            latitude: null
        },
        lang: null,
        id: null
    });
    const [fromTo, setFromTo] = useState(["-100", "-100"]);
    const [places, setPlaces] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [late, setLate] = useState(false);
    const [draggingtimer, setDraggingTimer] = useState(null);
    const [scrollContainer, setScrollContainer] = useState(null);

    useEffect(() => {
        const d = new Date();
        const utc = d.getTime() + (d.getTimezoneOffset() * 60000);
        const nd = new Date(utc + (3600000 * 2));
        setLate((nd.getHours() < 8 || nd.getHours() > 23) ? `${nd.getHours()}:${nd.getMinutes()}` : false);
    }, []);

    useEffect(() => {
        setFromTo([overlay.places ? "-100" : "0", overlay.places ? "0" : "-100"]);
    }, [overlay]);
    useEffect(() => {
        if (overlay.places && fetchDataCoords && position) {
            let refetch = false;
            if (currentRequest.lang !== lang) {
                refetch = true;
            } else if (
                currentRequest.coords.latitude !== fetchDataCoords.latitude &&
                currentRequest.coords.longitude !== fetchDataCoords.longitude
            ) {
                refetch = true;
            } else if (currentRequest.id !== filterId) {
                refetch = true;
            }

            if (refetch) {
                setPlaces(null);
                setLoaded(false);
                setCurrentRequest({
                    coords: { ...fetchDataCoords },
                    lang,
                    id: filterId
                });
                if (zoneCall) {
                    getPlacesByZone({
                        ...fetchDataCoords,
                        lang,
                        userLat: position.latitude ? position.latitude : centerOfBrussels[0],
                        userLng: position.longitude ? position.longitude : centerOfBrussels[1],
                        filterId: filterId === "all" ? null : filterId
                    }).then(r => {
                        setZoneCall(false);
                        if (r.data.data.length !== 0) {
                            setLoaded(true);
                            setPlaces(r.data.data);
                            if (scrollContainer) scrollContainer.scrollTop = 0;
                        } else {
                            getPlaces({
                                ...fetchDataCoords,
                                lang,
                                userLat: position.latitude ? position.latitude : centerOfBrussels[0],
                                userLng: position.longitude ? position.longitude : centerOfBrussels[1],
                                filterId: filterId === "all" ? null : filterId
                            }).then(r => {
                                setLoaded(true);
                                setPlaces(r.data.data);
                                if (scrollContainer) scrollContainer.scrollTop = 0;
                            });
                        }
                    });
                } else {
                    getPlaces({
                        ...fetchDataCoords,
                        lang,
                        userLat: position.latitude ? position.latitude : centerOfBrussels[0],
                        userLng: position.longitude ? position.longitude : centerOfBrussels[1],
                        filterId: filterId === "all" ? null : filterId
                    }).then(r => {
                        setLoaded(true);
                        setPlaces(r.data.data);
                        if (scrollContainer) scrollContainer.scrollTop = 0;
                    });
                }
            }
        }
    }, [
        currentRequest,
        fetchDataCoords,
        filterId,
        lang,
        overlay,
        position,
        scrollContainer,
        setZoneCall,
        zoneCall
    ]);

    const isMobile = window.innerWidth < Breakpoints.tablet;

    const ScrollToBrussels = useCallback(() => {
        setFetchDataCoords({
            latitude: centerOfBrussels[0],
            longitude: centerOfBrussels[1]
        });
        setCenterOverride({
            latitude: centerOfBrussels[0],
            longitude: centerOfBrussels[1]
        });

        dragOverride.current = true

        clearTimeout(draggingtimer);
        setDraggingTimer(
            setTimeout(() => { dragOverride.current = false }, 25000)
        );
    }, [setCenterOverride, setFetchDataCoords])

    return (
        <Container open={overlay.places} to={fromTo[1]} from={fromTo[0]}>
            <div style={{height: '100%'}}>
                <Card height="100px">
                  <Info>
                    <Title>{langSrc.offlineTitle}</Title>
                    <Text>{langSrc.offlineText}</Text>
                  </Info>
                </Card>
                <Card>
                    <CloseBtn
                        open={overlay.places}
                        onClick={
                            overlay.places
                            ? () => {
                                setOverlay({ ...overlay, places: false });
                                setDetailView(null);
                                scrollContainer.scrollTop = 0;
                            }
                            : () => setOverlay({ ...overlay, places: true })
                        }
                        />
                    <Content ref={r => setScrollContainer(r)}>
                        <TitleLine />
                        <Title>{getTitle(category, langSrc)}</Title>
                        {(!places || places.length < 8) && (late !== false) && (
                            <LateInBrussels>
                                <Icon src={moon} />
                                {langSrc.lateInBrussels.replace(
                                    "{{hour}}",
                                    late
                                )}
                            </LateInBrussels>
                        )}
                        {places
                            ? places.map((e, i) =>
                            renderEventItem(
                                { ...e, last: i === places.length - 1 },
                                p => {
                                    setDetailView(p);
                                },
                                i,
                                inBrussel
                            )
                        )
                        : RenderPlaceholders()}
                        {(!places || places.length === 0) && loaded && (
                            <NoFound>
                                {langSrc.noLocationsFound}
                                <Button
                                    center
                                    label={langSrc.goToTheCenter}
                                    callback={ScrollToBrussels}
                                    />
                            </NoFound>
                        )}
                        {places && places.length !== 0 && (
                            <Button
                                center
                                label={langSrc.goToTop}
                                arrow="up"
                                callback={() => {
                                    scrollContainer.scrollTop = 0;
                                }}
                                />
                        )}
                    </Content>
                </Card>
            </div>
            {detailView && !isMobile && (
                <DetailCard>
                    <PlaceDetail {...detailView} desktop />
                </DetailCard>
            )}
        </Container>
    );
};

export default EventListScreen;
