window.dataLayer = window.dataLayer || [];

export function sendEvent(eventCategory, eventAction, eventLabel) {
    const obj = {
        event: "GAEvent",
        eventCategory: eventCategory,
        eventAction: eventAction,
        eventLabel: eventLabel
    }

    window.dataLayer.push(obj);
}

export function sendLeadEvent(clicked) {
  const obj = {
      event: "Lead",
      buttonClick: clicked
  }

  window.dataLayer.push(obj);
}

export function virtualPageView(url, title) {
  const obj = {
      event: "VirtualPageview",
      virtualPageURL: url.replace(" ", "_"),
      virtualPageTitle: title
  }

  window.dataLayer.push(obj);
}
