import React, { useContext } from "react";
import {
  Container,
  Card,
  Info,
  Title,
  Text,
  ButtonContainer,
  Deny
} from "./locationScreen.style";
import Button from "../../components/Button";
import { LanguageContext } from "../../contexts";
import { sendEvent } from "../../helpers/gtm";

const LocationScreen = ({ setLocation }) => {
  const { langSrc } = useContext(LanguageContext);
  return (
    <Container>
      <Card>
        <Info>
          <Title>{langSrc.askLocationTitle}</Title>
          <Text>{langSrc.askLocationText}</Text>
          <ButtonContainer>
            <Button
              callback={() => {
                sendEvent("Geo", "Accept", "");

                setLocation(true);
              }}
              label={langSrc.askLocationAccept}
            />
            <Deny
              onClick={() => {
                sendEvent("Geo", "Deny", "");

                setLocation(false);
              }}
            >
              {langSrc.askLocationReject}
            </Deny>
          </ButtonContainer>
        </Info>
      </Card>
    </Container>
  );
};

export default LocationScreen;
