import React, { useState, useEffect, useCallback } from "react";
import {
    Container,
    SlideContainer,
    SlideItem,
    Slider,
    SlideBubble,
    SlideBubbleContainer,
    SlideBubbles,
    SlideBubblesContainer,
    LeftSlide,
    RightSlide
} from "./imageSlider.style";
const swipeThreshold = 100;

function getDirection(e, start, pos, images) {
    let newPos = pos;
    const end = e.changedTouches[0].clientX;
    if (end > start) {
        const diff = end - start;
        if (diff > swipeThreshold) {
            newPos--;
        }
    } else {
        const diff = start - end;
        if (diff > swipeThreshold) {
            newPos++;
        }
    }
    if (newPos >= 0 && newPos <= images.length - 1) {
        return newPos;
    }
    return pos;
}

function ImageSlider({ images }) {
    const [startSwipe, setStartSwipe] = useState(0);
    const [swipeIndex, setSwipeIndex] = useState(0);
    const [contentPos, setContentPos] = useState([0, 0]);
    const [oldImages, setOldImages] = useState(images);
    const updatePosition = newPos => {
        setSwipeIndex(newPos);
        setContentPos([contentPos[1], imagePos[newPos]]);
    };
    useEffect(() => {
        if (images !== oldImages) {
            setSwipeIndex(0);
            setContentPos([0, 0]);
            setStartSwipe(0);
            setOldImages(images);
        }
    }, [
        images,
        oldImages,
        setSwipeIndex,
        setContentPos,
        setStartSwipe,
        setOldImages
    ]);
    const imagePos = images.map((img, i) => -100 * i);
    const bubbleWidth = images.length >= 5 ? 20 : 100 / images.length;

    const lowCutOff = 3;
    const highCutOff = images.length - 4;
    const isSmall = useCallback(
        index => {
            // Active is always big
            if (index === swipeIndex || images.length < 8) {
                return false;
            }
            // Last and first always big
            if ([0, images.length - 1].includes(index)) {
                return false;
            }

            // Calculate the rest
            if (swipeIndex < lowCutOff) {
                return [lowCutOff - 1, lowCutOff, lowCutOff + 1].includes(index) ? true : false;
            } else if (swipeIndex >= lowCutOff && swipeIndex <= highCutOff) {
                return true;
            } else {
                return [highCutOff - 1, highCutOff, highCutOff + 1].includes(index) ? true : false;
            }
        },
        [highCutOff, images.length, swipeIndex]
    );

    const getActivePosition = useCallback(() => {
        if (swipeIndex < 2) return 0;
        if (swipeIndex >= 2 && swipeIndex < images.length - 3)
        return (swipeIndex - 2) * 20;
        if (swipeIndex >= images.length - 3) return (images.length - 5) * 20;
    }, [images.length, swipeIndex]);

    return (
        <Container>
            <LeftSlide
                onClick={() =>
                    updatePosition(swipeIndex !== 0 ? swipeIndex - 1 : swipeIndex)
                }
            />
            <RightSlide
                onClick={() =>
                    updatePosition(
                        swipeIndex !== images.length - 1 ? swipeIndex + 1 : swipeIndex
                    )
                }
            />
            <SlideContainer>
                <Slider
                    from={contentPos[0]}
                    to={contentPos[1]}
                    onTouchStart={e => setStartSwipe(e.changedTouches[0].clientX)}
                    onTouchEnd={e =>
                        updatePosition(getDirection(e, startSwipe, swipeIndex, images))
                    }
                >
                    {images.map((i, index) => (
                        <SlideItem key={index} img={i.image}>
                          {i.name && <span>&copy;{i.name}</span>}
                        </SlideItem>
                    ))}
                </Slider>
            </SlideContainer>
            <SlideBubblesContainer>
                <SlideBubbles
                    position={getActivePosition}
                    >
                    {images.map((i, index) => (
                        <SlideBubbleContainer width={bubbleWidth} key={index}>
                            <SlideBubble
                                small={isSmall(index)}
                                onClick={() => {
                                    updatePosition(index)
                                }}
                                active={index === swipeIndex}
                            />
                        </SlideBubbleContainer>
                    ))}
                </SlideBubbles>
            </SlideBubblesContainer>
        </Container>
    );
}

export default ImageSlider;
