import styled from "styled-components";
import colors from "../../helpers/colors";
import { media } from "../../helpers/mediaQueries";
import { ReactComponent as LogoSVG } from "../../assets/logos/logo_long.svg";

export const HeaderContainer = styled.div`
  position: fixed;
  z-index: 10;
  display: flex;
  height: 80px;
  background-color: ${colors.white};
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  a {
    text-decoration: none;
  }
  ${media.tablet`
      background-color: transparent;
    `}
`;

export const Logo = styled(LogoSVG)`
  margin-left: 20px;
  width: 200px;
  height: 100%;
  cursor: pointer;
`;

export const FilterText = styled.span`
  font-family: "Axiforma";
  font-size: 14px;
  color: ${colors.darkblue};
  margin-right: 20px;
`;

export const Menu = styled.div`
  display: none;
  cursor: pointer;
  z-index: 1;
  ${media.laptop`
      display: ${props => (props.hideMenu ? "none" : "flex")};
    `}
  align-items: center;
`;

export const MobileMenu = styled.div`
  cursor: pointer;
  display: ${props => (props.hideMenu ? "none" : "block")};
  ${media.laptop`
      display: none;
    `}
`;

export const Icon = styled.div`
  width: 26px;
  height: 26px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.icon});
  :not(:last-child) {
    margin-right: 50px;
  }
`;

export const MenuIcon = styled.div`
  width: 80px;
  height: 80px;
  display: ${props => (props.hide ? "none" : "block")};
  cursor: pointer;
`;

export const MenuCategoryIcon = styled.div`
  margin: 15px;
  height: 50px;
  border-radius: 50px;
  background-image: url(${props => props.icon});
  background-position: center;
  width: 50px;
  background-size: cover;
  cursor: pointer;
  display: ${props => (props.hide ? "none" : "block")};
  background-color: ${colors.grey};

  ${media.tablet`
    transition: transform 0.4s;
    :hover{
      transform: scale(${props => (props.active ? 1 : 1.2)});
    }
    ${props =>
      props.active &&
      `
      
      background-color: ${colors.yellow};
      border-radius: 50px;
    `}
    
    `}
`;
