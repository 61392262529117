import React, { useContext } from "react";
import {
  Container,
  Card,
  Info,
  Title,
  Text,
  ButtonContainer,
  Deny
} from "./cookieScreen.style";
import Button from "../../components/Button";
import { LanguageContext } from "../../contexts";
import { sendEvent } from "../../helpers/gtm";

const CookieScreen = ({ setConsentGiven }) => {
  const { langSrc, lang } = useContext(LanguageContext);
  return (
    <Container>
      <Card>
        <Info>
          <Title>{langSrc.cookieTitle}</Title>
          <Text>
              {langSrc.cookieDescription}
              <a
                href={`/assets/pdf/cookies${lang.toUpperCase()}.pdf`}
                target="_blank"
                rel="noreferrer noopener"
              >
                <Deny>{langSrc.readMore}</Deny>
              </a>
          </Text>
          <ButtonContainer>
            <Button
              blue
              callback={() => {
                sendEvent("Cookie", "Accept", "");
                setConsentGiven(true);
              }}
              label={langSrc.cookieConfirm}
            />
            <Deny
              onClick={() => {
                sendEvent("Cookie", "Deny", "");
                setConsentGiven(false);
              }}
            >
              {langSrc.cookieDecline}
            </Deny>
          </ButtonContainer>
        </Info>
      </Card>
    </Container>
  );
};

export default CookieScreen;
