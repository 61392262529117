import React from "react";
import { string, func, bool } from "prop-types";
import {
  Container,
  Icon,
  Content,
  Title,
  SubTitle,
  Description
} from "./themeItem.style";
const ThemeItem = ({
  title,
  icon,
  subtitle,
  description,
  onClick,
  isActive
}) => (
  <Container isActive={isActive} onClick={onClick}>
    <Icon src={icon} />
    <Content>
      <Title>{title}</Title>
      {subtitle && <SubTitle>{subtitle}</SubTitle>}
      {description && <Description>{description}</Description>}
    </Content>
  </Container>
);

ThemeItem.propTypes = {
  title: string.isRequired,
  subtitle: string,
  description: string,
  icon: string.isRequired,
  onClick: func.isRequired,
  isActive: bool.isRequired
};

export default ThemeItem;
