import React from "react";
import PropTypes from "prop-types";
import placeholder from "../../assets/images/eventplaceholder.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import {
    Container,
    Content,
    ImageContainer,
    Name,
    Type,
    DistanceIcon,
    LocationInfo,
    Section
} from "./placeItem.style";



const EventItem = ({
    size,
    name,
    image,
    type,
    distanceTo,
    location,
    setDetailView,
    id,
    last,
    lazy
}) => {
    const detailAction = () =>
    setDetailView({
        id,
        size,
        name,
        image,
        type,
        distanceTo,
        location
    });

    const style = image ? image.replace('500x', '10x') : '#FFF'
    const Lazy = lazy ? LazyLoadImage : 'img'

    return (
        <Container last={last} size={size} onClick={detailAction}>
            <Content size={size}>
                <ImageContainer size={size} blur={style}>
                    <Lazy
                        alt="place-in-brussels"
                        height="100%"
                        src={image}
                        onError={e => {
                            e.target.onerror = null;
                            e.target.src = placeholder;
                        }}
                        threshold={500}
                        onLoad={e => {
                            e.target.className = 'loaded';
                        }}
                        width="100%" />
                </ImageContainer>
                <Section>
                    <Type>{type}</Type>
                    <Name>{name}</Name>

                    {(distanceTo || distanceTo === 0) && (
                        <LocationInfo>
                            <span>
                                <DistanceIcon />
                                {distanceTo < 1 ? `${distanceTo * 1000}m` : `${distanceTo}km`}
                            </span>
                        </LocationInfo>
                    )}
                </Section>
            </Content>
        </Container>
    );
};

EventItem.propTypes = {
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
    distanceTo: PropTypes.number,
    location: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    size: PropTypes.oneOf(["small", "medium", "large"]).isRequired,
    last: PropTypes.bool
};

EventItem.defaultProps = {
    last: false,
    image: null,
    distanceTo: null
};

export default EventItem;
