import call from "./apicall";

/**
 * gets categories
 * @constructor
 */
export const getZones = () => {
  if (process.env.NODE_ENV === "development") {
    console.info("API: getZones");
  }
  return call("zones", 'nl', {});
};
