import styled from "styled-components";
import colors from "../../helpers/colors";
export const Container = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
  height: 100px;
  background-color: ${props => (props.isActive ? colors.yellow : colors.white)};
  border-radius: 10px;
  align-items: center;
  padding: 15px 30px;
  box-sizing: border-box;
  :not(:last-child) {
    margin-bottom: 20px;
  }

  & span {
    margin-left: ${props => (props.noIcon ? "90px" : "0")};

    text-transform: capitalize;
    font-size: 16px;
    font-weight: bold;
  }
`;

export const Icon = styled.div`
  height: 75px;
  background-image: url(${props => props.src});
  background-position: center;
  width: 70px;
  background-size: cover;
  margin-right: 20px;
`;
