import styled, { keyframes } from "styled-components";
import colors from "../../helpers/colors";
import { ReactComponent as LogoSVG } from "../../assets/logos/vblogo.svg";
import { media } from "../../helpers/mediaQueries";

export const Container = styled.div`
  position: fixed;
  z-index: 6;
  background-color: ${colors.grey};
  margin-top: 80px;
  height: calc(100% - 80px);
  opacity: ${props => (props.hide ? 0 : 1)};

  ${media.tablet`
  margin-top: 0;
  padding-top: 80px;
  box-sizing: border-box;
  height: 100%;
  `}
  width: 100%;
  top: ${props => props.to}%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: top 0.6s;
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

export const SlideContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  width: 300%;
  height: 100%;
  left: ${props => props.to}%;
  transition: left 0.4s;
  /* animation: ${props => slide(props.from, props.to)} 0.4s ease-in; */
`;
export const SlideContext = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  /* -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth; */
`;
export const Categories = styled.div`
  width: fit-content;
  min-width: 100%;
  height: 100%;
  padding: 20px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  ${media.tablet`
    min-width: 800px;
  `}
`;

export const Themes = styled.div`
  width: fit-content;

  padding: 20px;
  padding-top: 10px;
  box-sizing: border-box;
  height: 100%;
  
  display: flex;
  flex-direction: column;
  ${media.tablet`
    min-width: 800px;
    display: grid;
    grid-gap: 20px;
    padding:20px 100px;
    grid-template-columns: repeat(2, 1fr); 
    grid-auto-flow: row;
    grid-auto-rows: 400px;
  `}
  ${media.laptop`
    min-width: 1200px;
    padding:20px 200px;
  `}

  ${media.laptopL`
    min-width: 1400px;
    padding:20px 300px;
    grid-template-columns: repeat(3, 1fr); 
  `}
`;

export const Menu = styled.div`
  position: relative;
  z-index: 5;
  width: 100%;
  ${media.laptop`
    width: 50%;
    `}
  padding: 20px;
  padding-bottom: 10px;
  box-sizing: border-box;
`;

export const MenuItems = styled.div`
  display: flex;
  z-index: 2;
  width: 100%;
  margin-bottom: 20px;
`;

export const MenuItem = styled.div`
  color: ${props => (props.active ? colors.darkgrey : "#B0BCC2")};
  z-index: 1;
  width: ${props => (props.third ? 33 : 50)}%;
  cursor: pointer;
  text-align: center;
  font-size: 13px;
`;

export const IndicatorContainer = styled.div`
  width: 100%;
  display: flex;
  height: 5px;
  align-items: center;
  position: relative;
`;
const slide = (from, to) => keyframes`
  from {
    left: ${from}%;
  }

  to {
    left: ${to}%;
  }
`;

export const Indicator = styled.div`
  width: ${props => (props.third ? 33.33 : 50)}%;
  z-index: 1;
  position: absolute;
  height: 100%;
  top: 0px;
  border-radius: 25px;
  left: ${props => props.to}%;
  background-color: ${colors.yellow};
  transition: left 0.3s;
  animation: ${props => slide(props.from, props.to)} 0.3s ease-in;
`;

export const Line = styled.div`
  width: 100%;
  border-radius: 1px;
  height: 1px;
  opacity: 0.7;
  background-color: ${colors.white};
`;

export const CategoryFooter = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  ${media.tablet`
    display: none;
    `}
`;

export const DesktopCategoryFooter = styled.div`
  margin-top: auto;
  display: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  ${media.tablet`
    display: flex;
    position: absolute;
    bottom: 0;
    `}
`;

export const ChangeLanguageContainer = styled.div`
  position: relative;
  width: 52px;
  height: 52px;
`;

export const ChangeLanguage = styled.div`
  background-color: ${props => (props.isOpen ? colors.grey : colors.yellow)};
  width: 52px;
  height: 52px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 52px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  z-index: 1;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
`;

export const ChangeLanguageCard = styled.div`
  position: absolute;
  z-index: 0;
  right: -5px;
  bottom: -5px;
  border-radius: 10px;
  background-color: ${colors.white};
  padding: 20px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15), 0 10px 20px 0 rgba(0, 0, 0, 0.14);
`;

export const Languages = styled.div`
  margin-right: 80px;
`;
export const LangDivider = styled.div`
  height: 2px;
  background-color: ${colors.yellow};
  width: 60px;
  margin: 10px 0;
`;
export const Language = styled.span`
  color: ${props => (props.active ? colors.yellow : colors.black)};
  display: block;
  cursor: pointer;
`;

export const PoweredByLogo = styled(LogoSVG)`
  height: 10px;
`;
