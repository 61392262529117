import styled from "styled-components";
import colors from "../../helpers/colors";

export const Container = styled.div`
  position: absolute;
  z-index: 22;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
`;

export const Languages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const LangDivider = styled.div`
  height: 2px;
  background-color: ${colors.yellow};
  width: 60px;
  margin: 20px 0;
`;
export const Language = styled.span`
  color: ${props => (props.active ? colors.yellow : colors.black)};
  display: block;
  text-align: center;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
`;
