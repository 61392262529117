import styled from "styled-components";
import { ReactComponent as PopulationSVG } from "../../assets/icons/population.svg";
import colors from "../../helpers/colors";
export const Container = styled.div`
  flex-direction: ${props => (props.size === "large" ? "column" : "row")};
  padding-bottom: 20px;
  cursor: pointer;
  position: relative;

  :hover {
    & img {
      transform: scale(1.2)};
      }
  }
  &:not(:last-child) {
    border-bottom: ${props =>
      !props.last ? `1px solid ${colors.grey}` : `none`};
  }
  &:not(:first-child) {
    padding-top: 20px;
  }
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: ${props => (props.size === "large" ? "column" : "row")};
  width: 100%;
`;

export const ImageContainer = styled.div`
  overflow: hidden;
  width: ${props => (props.size === "medium" ? "100px" : "100%")};
  height: ${props => (props.size === "medium" ? "100px" : "150px")};
  margin-bottom: ${props => (props.size === "large" ? "20px" : "0")};
  margin-right: ${props => (props.size === "large" ? 0 : 10)}px;
  display: ${props => (props.size === "small" ? "none" : "block")};
  flex: none;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  z-index: 2;
  background: #f3f3f3 url(${props => (props.blur)});
  background-size: cover;
  background-position: center;

  img {
    opacity: 0;
    z-index: 1;
    object-fit: cover;
    position: absolute;
    transition: all 0.5s ease;
  }

  img.loaded {
    opacity: 1;
  }
`;

export const Name = styled.span`
  font-size: 20px;
  line-height: 22px;
  display: block;
  margin: 10px 0;
`;

export const Type = styled.span`
  opacity: 0.3;
  display: block;
  font-size: 12px;
`;

export const LocationInfo = styled.span`
  font-size: 10px;
  opacity: 0.5;
  & > span:not(:first-child) {
    margin-left: 20px;
  }
`;

export const DistanceIcon = styled(PopulationSVG)`
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 5px;
`;

export const Section = styled.div`
  padding: 0;
  position: relative;
  width: 100%;
  box-sizing: border-box;
`;
