const southWestCorner = [50.764127, 4.243508];
const northEastCorner = [50.913885, 4.480995];
export const centerOfBrussels = [50.8503, 4.3517];

export function checkLatitude(lat, center = false) {
  if (lat < southWestCorner[0]) {
    return center ? centerOfBrussels[0] : southWestCorner[0];
  }
  if (lat > northEastCorner[0]) {
    return center ? centerOfBrussels[0] : northEastCorner[0];
  }
  return lat;
}

export function checkLongitude(long, center = false) {
  if (long < southWestCorner[1]) {
    return center ? centerOfBrussels[1] : southWestCorner[1];
  }
  if (long > northEastCorner[1]) {
    return center ? centerOfBrussels[1] : northEastCorner[1];
  }
  return long;
}

export function isInBrussel(lat, long) {
  if (checkLatitude(lat, true) === centerOfBrussels[0]) {
    return false;
  }
  if (checkLongitude(long, true) === centerOfBrussels[1]) {
    return false;
  }
  return true;
}
