import React, { useContext } from "react";
import {
  Container,
  Day,
  Month,
  DateField,
  Info,
  Title
} from "./calendarItem.style";
import { LanguageContext } from "../../contexts";
const months = {
  0: "jan",
  1: "feb",
  2: "mar",
  3: "apr",
  4: "may",
  5: "jun",
  6: "jul",
  7: "aug",
  8: "sep",
  9: "oct",
  10: "nov",
  11: "dec"
};
function CalendarItem({ name, date_next, onClick }) {
  const { langSrc } = useContext(LanguageContext);
  const date = new Date(date_next.replace(/\s/, "T"));
  const day = date.getDate();
  const month = date.getMonth();
  return (
    <Container onClick={onClick}>
      <DateField>
        <Day>{day}</Day>
        <Month>{langSrc[months[month]]}</Month>
      </DateField>
      <Info>
        <Title>{name}</Title>
      </Info>
    </Container>
  );
}

export default CalendarItem;
