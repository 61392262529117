import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
    Container,
    Card,
    Info,
    Title,
    Text,
    ButtonContainer,
    Deny
} from "./location.style";
import Button from "../../components/Button";
import { LanguageContext } from "../../contexts";

const UserLocationDenied = () => {
    const { langSrc } = useContext(LanguageContext);
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // ----------------------

    let type = langSrc.locationerrorBrowser;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        type = langSrc.locationerrorSettings;
    } else if (/windows phone|android/i.test(userAgent)) {
        type = langSrc.locationerrorSettings;
    }

    // ----------------------

    let browser

    if ((!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0) {
        browser = 'Opera'
    }

    if (typeof InstallTrigger !== 'undefined') {
        browser = 'Firefox'
    }

    if (/constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window.safari.pushNotification))) {
        browser = 'Safari'
    }

    if (!!document.documentMode) {
        browser = 'IE'
    }

    if (!!window.StyleMedia) {
        browser = 'Edge'
    }

    if (!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)) {
        browser = 'Chrome'
    }

    // ----------------------

    return (
        <Container>
            <Card>
                <Info>
                    <Title>{langSrc.locationerrorTitle}</Title>
                    <Text>
                        {langSrc.locationerrorText1} <strong>{type}</strong> {langSrc.locationerrorText2} <strong>{browser}</strong>.
                    </Text>
                    <ButtonContainer>
                        <Button
                            callback={() => {
                                window.location.reload()
                            }}
                            label={langSrc.locationerrorClose}
                            />
                    </ButtonContainer>
                </Info>
            </Card>
        </Container>
    );
};

export default UserLocationDenied;
