import React, {
    useState,
    useCallback,
    useEffect,
    useContext,
    Fragment,
    useRef
} from "react";

import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    ResponsiveContainer
} from "recharts";
import {
    LanguageContext,
    DetailContext,
    PositionContext
} from "../../contexts";
import ReactMapGL, { Marker } from "react-map-gl";
import PropTypes from "prop-types";
import { virtualPageView } from "../../helpers/gtm";
import ImageSlider from "./imageSlider";
import {
    Container,
    Content,
    Name,
    Type,
    DistanceIcon,
    LocationInfo,
    Address,
    AddressIcon,
    Description,
    ReadMore,
    CloseBtn,
    ButtonContainer,
    ChartContainer,
    ChartContainerAddress,
    Section,
    CalendarEvents,
    SectionTitle,
    TitleAffix,
    Day,
    Month,
    DateField,
    OpeningTimesRow,
    OpeningTime,
    CoverImage
} from "./placeDetail.style";
import Button from "../Button";
import colors from "../../helpers/colors";
import CalendarItem from "./calendarItem";
import { distance } from "../../helpers/distance";
import { getPlaceDetail } from "../../api/places";
import { getEvent } from "../../api/events";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import Placeholder from "./placePlaceholder";
import EventPlaceholderImage from "../../assets/images/eventplaceholder.png";
import { sendLeadEvent } from "../../helpers/gtm";
import placeholder from "../../assets/images/eventplaceholder.png";

const months = {
    0: "jan",
    1: "feb",
    2: "mar",
    3: "apr",
    4: "may",
    5: "jun",
    6: "jul",
    7: "aug",
    8: "sep",
    9: "oct",
    10: "nov",
    11: "dec"
};
const spotColors = {
    1.3: "rgba(252, 244, 165, 0.7)",
    1.2: "rgba(255, 226, 131, 0.7)",
    1.1: "rgba(252, 200, 16, 0.7)",
    2.3: "rgba(208, 212, 232, 0.7)",
    2.2: "rgba(155, 165, 207, 0.7)",
    2.1: "rgba(55, 78, 162, 0.7)"
};
function CustomizedLabel({ x, y, stroke, payload }) {
    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dy={12}
                fontSize={8}
                textAnchor="middle"
                fill={colors.darkblue}
                >
                {payload.value}
            </text>
        </g>
    );
}

const PlaceDetail = ({
    name,
    image,
    type,
    distanceTo,
    id,
    desktop,
    isEvent
}) => {
    const { lang, langSrc } = useContext(LanguageContext);
    const { setDetailView } = useContext(DetailContext);
    const { position, inBrussel } = useContext(PositionContext);
    const [detail, setDetail] = useState(null);
    const [readMore, setReadMore] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const scrollContainer = useRef(null);
    const mapRef = useRef(null);

    useEffect(() => {
        scrollContainer.current.scrollTop = 0;
    }, [id, scrollContainer]);
    useEffect(() => {
        if (detail && detail.zone && detail.zone.polygon && mapRef) {
            const map = mapRef.current.getMap();
            map.on("load", () => {
                const coords = JSON.parse(detail.zone.polygon);
                const parsedCoords = coords.map(c => [
                    parseFloat(c[1]),
                    parseFloat(c[0])
                ]);
                if (map.getLayer("population-zone")) {
                    map.setPaintProperty(
                        "population-zone",
                        "fill-color",
                        spotColors[`${detail.zone.layer}.${detail.zone.zone}`]
                    );
                    map.getSource("population-zone").setData({
                        type: "Feature",
                        geometry: {
                            type: "Polygon",
                            coordinates: [parsedCoords]
                        }
                    });
                } else {
                    map.addLayer({
                        id: "population-zone",
                        type: "fill",
                        source: {
                            type: "geojson",
                            data: {
                                type: "Feature",
                                geometry: {
                                    type: "Polygon",
                                    coordinates: [parsedCoords]
                                }
                            }
                        },
                        layout: {},
                        paint: {
                            "fill-color": spotColors[`${detail.zone.layer}.${detail.zone.zone}`]
                        }
                    });
                }
            });
        }
    }, [detail, mapRef]);

    // set page view on different ID
    useEffect(() => {
        detail &&
        virtualPageView(`/${lang.toLowerCase()}/${detail.name}`, detail.name);
    }, [detail, lang]);

    useEffect(() => {
        setLoaded(false);
        if (isEvent) {
            getEvent({ id, lang }).then(r => {
                const { place } = r.data.data;
                const { name, url, ...placeRest } = place;
                setLoaded(true);
                setDetail({
                    ...r.data.data,
                    ...placeRest,
                    venueName: name,
                    venueUrl: url
                });
            });
        } else {
            getPlaceDetail({ id, lang }).then(r => {
                setLoaded(true);
                setDetail(r.data.data);
            });
        }
    }, [id, isEvent, lang, name]);
    const {
        date_next,
        address_line1,
        address_line2,
        description,
        organizer,
        venueName,
        dates
    } = detail || {};
    let coverImage =
    image ||
    (detail ? detail.cover.image : null) ||
    (detail && detail.cover && detail.cover[0] ? detail.cover[0].image : null);
    let coverCopy =
    (detail ? detail.cover.name : null) ||
    (detail && detail.cover && detail.cover[0] ? detail.cover[0].name : null);
    if (isEvent && !coverImage) {
        coverImage = EventPlaceholderImage;
    }
    let day = 0;
    let month = 0;
    if (date_next) {
        const date = new Date(date_next.replace(/\s/, "T"));
        day = date.getDate();
        month = date.getMonth();
    }
    let distanceToLocation = distanceTo;
    if (!distanceToLocation && detail) {
        distanceToLocation = distance(
            parseFloat(detail.lat),
            parseFloat(detail.lng),
            parseFloat(position.latitude),
            parseFloat(position.longitude)
        );
    }
    let concatDescription = false;
    let shortenedDiscription = "";
    if (description) {
        concatDescription = description.length > 350;
        shortenedDiscription = concatDescription
        ? `${description.slice(0, 350)}...`
        : description;
    }
    let placeName = name;
    let placeType = type;
    if (detail) {
        placeName = name || detail.name;
        placeType = type || detail.type;
    }

    const close = useCallback(() => setDetailView(null), [setDetailView]);
    const showMoreText = useCallback(() => setReadMore(true), []);
    const blur = coverImage ? coverImage.replace('500x', '10x') : '#FFF'

    return (
        <Container desktop={desktop}>
            <CloseBtn onClick={close} />
            <Content ref={scrollContainer}>
                {!loaded && <Placeholder />}
                {loaded && (
                    <Fragment>
                        {coverImage && (<CoverImage blur={blur}>
                                <img
                                    alt="placedetail"
                                    height="100%"
                                    src={coverImage}
                                    onError={e => {
                                        e.target.onerror = null;
                                        e.target.src = placeholder;
                                    }}
                                    onLoad={e => {
                                        e.target.className = 'loaded';
                                    }}
                                    width="100%"
                                    />
                                {coverCopy && <span>&copy;{coverCopy}</span>}
                            </CoverImage>
                        )}

                        {date_next && (
                            <DateField>
                                <Day>{day}</Day>
                                <Month>{langSrc[months[month]]}</Month>
                            </DateField>
                        )}
                        <Section>
                            <Type>{placeType}</Type>
                            <Name>
                                {placeName}
                                {organizer && ` by ${organizer}`}
                            </Name>

                            {inBrussel && distanceToLocation && (
                                <LocationInfo>
                                    <span>
                                        <DistanceIcon />
                                        {distanceToLocation < 1
                                            ? `${distanceToLocation * 1000}m`
                                            : `${distanceToLocation}km`}
                                        </span>
                                    </LocationInfo>
                                )}
                                {address_line1 && address_line2 && (
                                    <Address>
                                        <AddressIcon />
                                        <span>
                                            {venueName && `${venueName} `}
                                            {`${address_line1} ${address_line2}`}
                                        </span>
                                    </Address>
                                )}
                                {description && (
                                    <Description>
                                        {readMore ? description : shortenedDiscription}
                                        {concatDescription && !readMore && (
                                            <ReadMore onClick={showMoreText}>
                                                {langSrc.readMore}
                                            </ReadMore>
                                        )}
                                    </Description>
                                )}
                                <ButtonContainer>
                                    {detail && detail.url && (
                                        <a
                                            onClick={() => sendLeadEvent("Website")}
                                            href={detail.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            >
                                            <Button label={langSrc.website} callback={() => null} />
                                        </a>
                                    )}
                                    {detail && detail.lat && (
                                        <a
                                            onClick={() => sendLeadEvent("Directions")}
                                            href={`https://www.google.com/maps/place/${detail.lat},${detail.lng}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            >
                                            <Button label={langSrc.directions} callback={() => null} />
                                        </a>
                                    )}
                                </ButtonContainer>
                            </Section>
                            {dates && dates.length !== 0 && (
                                <Section>
                                    <SectionTitle>{langSrc.openingHours}</SectionTitle>
                                    <div>
                                        {dates.map(d => (
                                            <OpeningTimesRow>
                                                <OpeningTime>
                                                    {new Date(d.day.replace(/\s/, "T")).getDate()}{" "}
                                                    {months[new Date(d.day.replace(/\s/, "T")).getMonth()]}
                                                </OpeningTime>
                                                <OpeningTime>{`${d.start.slice(0, 5)} - ${d.end.slice(0, 5)}`}</OpeningTime>
                                                </OpeningTimesRow>
                                            ))}
                                        </div>
                                    </Section>
                                )}
                                {detail && detail.images && detail.images.length !== 0 && (
                                    <Section>
                                        <SectionTitle>
                                            {langSrc.photos}
                                            <TitleAffix>({detail.images.length})</TitleAffix>
                                        </SectionTitle>
                                        <ImageSlider images={detail.images} />
                                    </Section>
                                )}
                                {detail && detail.lat && detail.lng && (
                                    <Section>
                                        <ChartContainer>
                                            <SectionTitle>{langSrc.recentActivity}</SectionTitle>
                                            <ReactMapGL
                                                ref={mapRef}
                                                zoom={15}
                                                latitude={parseFloat(detail.lat)}
                                                longitude={parseFloat(detail.lng)}
                                                width="100%"
                                                attributionControl={false}
                                                height="300px"
                                                mapStyle="mapbox://styles/arnovanbiesen/cjww0wcwu01rd1cmj4zfa63g6"
                                                mapboxApiAccessToken="pk.eyJ1IjoiYXJub3ZhbmJpZXNlbiIsImEiOiJjanhkMnoyMmswMHhpM3pwMHFlcDZydjBrIn0.snmC_w-aD_y0CCR9xyzDrg"
                                            >
                                            <Marker
                                                latitude={parseFloat(detail.lat)}
                                                longitude={parseFloat(detail.lng)}
                                                offsetLeft={-20}
                                                offsetTop={-10}
                                            >
                                                <AddressIcon size="30px" />
                                            </Marker>
                                        </ReactMapGL>
                                    </ChartContainer>
                                    <ChartContainerAddress>
                                        {address_line1 && address_line2 && (
                                            <Address>
                                                <AddressIcon />
                                                <span>{`${address_line1} ${address_line2}`}</span>
                                            </Address>
                                        )}
                                        {detail && detail.lat && (
                                            <a
                                                href={`https://www.google.com/maps/place/${detail.lat},${detail.lng}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                            <Button
                                                label={langSrc.directions}
                                                callback={() => null}
                                            />
                                        </a>
                                    )}
                                </ChartContainerAddress>
                            </Section>
                        )}
                        {detail && detail.popular && detail.popular.length !== 0 && (
                            <Section>
                                <SectionTitle>{langSrc.popularTimes}</SectionTitle>
                                <ChartContainer>
                                    <ResponsiveContainer width={"100%"} height={200}>
                                        <LineChart
                                            data={Object.keys(detail.popular).map(h => ({
                                                name: h,
                                                uv: detail.popular[h]
                                            }))}
                                            >
                                            <CartesianGrid stroke={colors.grey} horizontal={false} />
                                            <XAxis
                                                minTickGap={-5}
                                                tick={<CustomizedLabel />}
                                                stroke={colors.darkergrey}
                                                dataKey="name"
                                                />
                                            <Line
                                                // type="number"
                                                dot={false}
                                                dataKey="uv"
                                                stroke={colors.blue}
                                                strokeWidth={4}
                                                // scale="time"
                                                />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </ChartContainer>
                            </Section>
                        )}
                        {detail && detail.events && detail.events.length !== 0 && (
                            <LazyLoadComponent>
                                <Section>
                                    <SectionTitle>{langSrc.upcomingEvents}</SectionTitle>
                                    <Type>{langSrc.by}</Type>
                                    <CalendarEvents>
                                        {detail.events.map(c => (
                                            <CalendarItem
                                                key={c.id}
                                                {...c}
                                                onClick={() =>
                                                    setDetailView({ id: c.id, isEvent: true })
                                                }
                                                />
                                        ))}
                                    </CalendarEvents>
                                </Section>
                            </LazyLoadComponent>
                        )}
                    </Fragment>
                )}
            </Content>
        </Container>
    );
};

PlaceDetail.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    image: PropTypes.string,
    distanceTo: PropTypes.number,
    location: PropTypes.string,
    type: PropTypes.string
};

PlaceDetail.defaultProps = {};

export default PlaceDetail;
