import ContentLoader from "react-content-loader";
import React from "react";
import {
  Placeholder
} from "./placesScreen.style";

export const BigPlaceholder = () => (
  <Placeholder>
    <ContentLoader
      height={100}
      width={345}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="0" y="0" rx="10" ry="10" width="100" height="100" />
      <rect x="112" y="0" rx="3" ry="3" width="120" height="15" />
      <rect x="112" y="25" rx="3" ry="3" width="165" height="23" />
      <rect x="112" y="62" rx="3" ry="3" width="148" height="16" />
    </ContentLoader>
  </Placeholder>
);

export const MediumPlaceholder = () => (
  <ContentLoader
    height={400}
    width={335}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="8" y="5" rx="10" ry="10" width="294" height="200" />
    <rect x="10" y="217" rx="3" ry="3" width="120" height="15" />
    <rect x="10" y="239" rx="3" ry="3" width="165" height="23" />
    <rect x="10" y="269" rx="3" ry="3" width="148" height="16" />
  </ContentLoader>
);

export const SmallPlaceholder = () => (
  <ContentLoader
    height={400}
    width={335}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="11" y="6" rx="3" ry="3" width="120" height="15" />
    <rect x="10" y="38" rx="3" ry="3" width="165" height="23" />
    <rect x="10" y="77" rx="3" ry="3" width="148" height="16" />
  </ContentLoader>
);
