import styled, { keyframes } from "styled-components";
import colors from "../../helpers/colors";
import { ReactComponent as ArrowSVG } from "../../assets/icons/chevron.svg";
import { media } from "../../helpers/mediaQueries";

const slide = (from, to) => keyframes`
  from {
    left: ${from}%;
  }

  to {
    left: ${to}%;
  }
`;
export const Container = styled.div`
  position: relative;
`;
export const SlideContainer = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 250px;
  border-radius: 20px;
  z-index: 2;
  background-color: ${colors.grey};
`;

export const Slider = styled.div`
  width: 100%;
  z-index: 1;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  left: ${props => props.to}%;
  animation: ${props => slide(props.from, props.to)} 0.4s ease-in;
`;
export const LeftSlide = styled(ArrowSVG)`
  position: absolute;
  left: 10px;
  fill: ${colors.darkgrey};
  background-color: ${colors.white};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.05);
  height: 16px;
  width: 16px;
  padding: 12px;
  display: none;
  border-radius: 40px;
  cursor: pointer;
 top: 105px;
  z-index: 3;
  :hover {
    fill: ${colors.yellow};
  }
  ${media.tablet`
    display: block;
  `}
`;

export const RightSlide = styled(ArrowSVG)`
  position: absolute;
  right: 10px;
  fill: ${colors.darkgrey};
  background-color: ${colors.white};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.05);
  height: 16px;
  width: 16px;
  padding: 12px;
  border-radius: 40px;
  display: none;
  cursor: pointer;
  z-index: 3;
  transform: rotate(180deg);
  top: 105px;
  :hover {
    fill: ${colors.yellow};
  }
  ${media.tablet`
    display: block;
  `}
`;

export const SlideItem = styled.div`
  width: 100%;
  flex: none;
  position: relative;
  background: #f3f3f3 url(${props => props.img});
  background-size: cover;
  background-position: center;
  & span {
    position: absolute;
    bottom: 10px;
    font-size: 8px;
    opacity: 0.8;
    right: 20px;
    color: #FFF;
  }
`;
export const SlideBubblesContainer = styled.div`
  width: 40%;
  margin: 0 30%;
  overflow: hidden;
  position: relative;
  margin-top: 15px;
  height: 15px;
`;
export const SlideBubbles = styled.div`
  position: absolute;
  left: -${props => props.position}%;
  width: 100%;
  transition: left 0.5s;
  display: flex;
  justify-content: flex-start;
`;
export const SlideBubbleContainer = styled.div`
  width: ${props => props.width}%;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
`;
export const SlideBubble = styled.div`
  width: ${props => (props.small ? 8 : 12)}px;
  height: ${props => (props.small ? 8 : 12)}px;
  border-radius: ${props => (props.small ? 8 : 12)}px;
  background-color: ${props => (props.active ? colors.yellow : colors.grey)};
  cursor: pointer;
  transition: background 180ms ease-in-out;

  &:hover {
    background-color: #ffe066
  }
`;
