import styled from "styled-components";
import colors from "../../helpers/colors";
import { media } from "../../helpers/mediaQueries";
export const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: ${props => (props.isActive ? colors.yellow : colors.white)};
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  flex: none;
  ${media.tablet`
     flex-direction: column;
    /* max-width: 300px; */
    height: 100%;
    `}

  :not(:last-child) {
    margin-bottom: 20px;
    ${media.tablet`
      margin-bottom: 0;
    `}
  }

  & span {
    text-transform: capitalize;
  }
`;

export const Image = styled.div`
  background-image: url(${props => props.image});
  background-position: center;
  width: 40%;
  background-size: cover;
  ${media.tablet`
    width: 100%;
    height: 200px;
    `}
`;

export const Content = styled.div`
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 60%;

  ${media.tablet`
    overflow-y: scroll;
    width: 100%;
    `}
`;

export const Title = styled.h3`
  margin: 0;
  text-transform: capitalize;
`;

export const SubTitle = styled.span`
  font-size: 10px;
  margin-bottom: 8px;
`;

export const Description = styled.p`
  font-size: 12px;
`;
