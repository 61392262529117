import React, { useContext, useState, useEffect } from "react";
import lottie from "lottie-web";
import { useDebouncedCallback } from "use-debounce";
import {
  OverlayContext,
  CategoryContext,
  LanguageContext,
  DetailContext
} from "../../contexts";
import {
  HeaderContainer,
  Menu,
  MobileMenu,
  Logo,
  MenuIcon,
  MenuCategoryIcon,
  FilterText
} from "./header.style";

import { getCategories } from "../../api/categories";

const Header = ({ hideMenu }) => {
  const { overlay, setOverlay } = useContext(OverlayContext);
  const { langSrc, lang } = useContext(LanguageContext);
  const { category, setCategory } = useContext(CategoryContext);
  const { setDetailView } = useContext(DetailContext);

  const [animRef, setAnimRef] = useState(null);
  const [animRefDesktop, setAnimRefDesktop] = useState(null);
  const [waitOpen, setWaitOpen] = useState(false);
  const [categories, setCategories] = useState(null);
  useEffect(() => {
    getCategories(lang).then(r => {
      if (r.data.data) {
        setCategories(r.data.data.categories);
      } else {
        setCategories(r.data.categories);
      }
    });
  }, [lang]);
  useEffect(
    () =>
      setAnimRef(
        lottie.loadAnimation({
          container: document.getElementById("closeIcon"), // the dom element that will contain the animation
          renderer: "svg",
          loop: false,
          autoplay: false,
          path: "assets/animations/menu_close.json" // the path to the animation json
        })
      ),
    []
  );
  useEffect(
    () =>
      setAnimRefDesktop(
        lottie.loadAnimation({
          container: document.getElementById("closeIconDesktop"), // the dom element that will contain the animation
          renderer: "svg",
          loop: false,
          autoplay: false,
          path: "/assets/animations/menu_close.json" // the path to the animation json
        })
      ),
    []
  );

  useEffect(() => {
    if (animRef && animRefDesktop) {
      animRefDesktop.setDirection(overlay.menu ? 1 : -1);
      animRef.setDirection(overlay.menu ? 1 : -1);
      animRefDesktop.play();
      animRef.play();
    }
  }, [animRef, animRefDesktop, overlay.menu]);
  const toggleMenu = () => {
    if (!waitOpen) {
      setOverlay({ ...overlay, menu: overlay.menu ? false : true });
      setDetailView(null);
      setWaitOpen(true);
    }
    setDebounce();
  };
  const [setDebounce] = useDebouncedCallback(() => {
    setWaitOpen(false);
  }, 300);
  return (
    <HeaderContainer>
      <Logo onClick={() => setOverlay({ ...overlay, menu: false })} />
      <Menu hideMenu={hideMenu}>
        <FilterText>{langSrc.filterByCategory}</FilterText>
        {categories &&
          categories.map(c => (
            <MenuCategoryIcon
              key={c.id}
              active={category.id === c.id}
              onClick={() => {
                category.id !== c.id &&
                  setOverlay({ places: true, menu: false });
                category.id !== c.id
                  ? setCategory({
                      id: c.id,
                      icon: c.icon,
                      type: c.type
                    })
                  : setCategory({ id: "all" });
              }}
              icon={c.icon}
            />
          ))}
        <MenuIcon
          id="closeIconDesktop"
          open={!overlay.menu}
          onClick={toggleMenu}
        />
      </Menu>
      <MobileMenu hideMenu={hideMenu}>
        <MenuIcon
          id="closeIcon"
          hide={category.id !== "all" && !overlay.menu}
          open={!overlay.menu}
          onClick={toggleMenu}
        />
        <MenuCategoryIcon
          hide={category.id === "all" || overlay.menu}
          onClick={toggleMenu}
          icon={category.icon}
        />
      </MobileMenu>
    </HeaderContainer>
  );
};

export default Header;
