import styled from "styled-components";
import colors from "../../helpers/colors";

export const LoadingContainer = styled.div`
position: absolute;
z-index: 100;
top: 50%;
left: 50%;
height: 100vh;
width: 100vh;
transform: translate(-50%,-50%);

@media (min-aspect-ratio: 1/1) {
  height: 100vw;
  width: 100vw;
}
`;
