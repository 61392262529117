export default {
  white: "#FFFFFF",
  black: "#2B2C2E",
  blue: "#3C53A4",
  greyblue: "#9AAEBE",
  darkblue: "#122D38",
  yellow: "#FFCB05",
  grey: "#f5f5f5",
  darkestgrey: "#2b2c2e",
  darkergrey: "#abb0b7",
  darkgrey: "#2b2c2e"
};
