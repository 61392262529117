import React from "react";
import PropTypes from "prop-types";
import { Container, ArrowIcon, Label } from "./button.style";
const directions = {
  up: 90,
  right: 180,
  down: 270,
  left: 0
};
const Button = ({ arrow, label, callback, disabled, blue, center }) => {
  return (
    <Container center={center} disabled={disabled} onClick={callback} blue={blue}>
      <Label spaceRight={arrow}>{label}</Label>
      {arrow && <ArrowIcon direction={directions[arrow]} />}
    </Container>
  );
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  arrow: PropTypes.oneOf(["up", "right"]),
  disabled: PropTypes.bool,
  blue: PropTypes.bool,
  center: PropTypes.bool
};

Button.defaultProps = {
  disabled: false,
  arrow: null,
  blue: false,
  center: false
};

export default Button;
